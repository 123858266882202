a {
    &.utrains-btn {
        text-align: center;
        border-radius: 5px;
        display: inline-block;
        height: 60px;
        line-height: 62px;
        color: var(--edu-btn-color);
        background: var(--color-primary);
        padding: 0 30px;
        font-size: 15px;
        font-weight: 500;
        transition: 0.4s;
        font-family: var(--font-secondary);
        border: 0 none;
        overflow: hidden;
        position: relative;
        z-index: 1;
        @media #{$sm-layout} {
            padding: 0 20px;
            font-size: 14px;
            height: 50px;
            line-height: 52px;
        }
        &:after {
            content: "";
            height: 100%;
            width: 0;
            background: linear-gradient(-90deg, #1e4e70 0%, #216596 100%); 
            // background: linear-gradient(-90deg, #31b978 0%, #1ab69d 100%); 
            border-radius: 5px;
            position: absolute; 
            top: 0;
            right: 0;
            bottom: 0;
            z-index: -1;
            transition: 0.4s;
        }
        i {
            padding-left: 6px;
            position: relative;
            font-size: 11px;
            @media #{$sm-layout} {
                font-size: 9px;
            }
        }

        &:hover {
            &:after {
                left: 0;
                width: 100%;
            }
        }
        
        &.btn-large {
            padding: 0 40px;
            i {
                top: 0;
                padding-left: 10px;
            }
        }

        &.btn-medium {
            height: 50px;
            line-height: 51px;
            padding: 0 25px;
        }

        &.btn-small {
            height: 40px;
            line-height: 40px;
            padding: 0 20px;
            font-size: 14px;
        }

        &.left-icon {
            i {
                padding-left: 0;
                padding-right: 10px;
            }
        }

        &.btn-curved {
            border-radius: 30px 5px;
            &:hover {
                border-radius: 5px 30px;
            }
        } 
        &.curved-medium {
            border-radius: 20px 5px;
            padding: 0 40px;
            &:hover {
                border-radius: 5px 25px;
            }
        }

        &.btn-secondary {
            background: var(--color-primary);
            color: var(--color-white);
            &:hover {
                color: var(--color-white);
                background: var(--color-primary);
            }
        }
        &.btn-secondary1 {
            background: var(--color-secondary);
            color: var(--color-white);
            &:hover {
                color: var(--color-heading);
                background: var(--color-white);
            }
        }
        &.btn-border {
            background-color: transparent;
            border: 1px solid var(--color-border);
            color: var(--color-heading);
            padding: 0 25px;
            &:after {
                display: none;
            }
            &:hover {
                background-color: var(--color-primary);
                border-color: var(--color-primary);
                color: var(--color-white);
            }
        }
        &.btn-border-curved {
            border-radius: 30px 5px;
            background-color: transparent;
            border: 1px solid var(--color-border);
            color: var(--color-white);
            padding: 0 25px;
            &:after {
                display: none;
            }
            &:hover {
                border-radius: 5px 30px;
                border-color: var(--color-primary);
                color: var(--color-primary);
            }
        }
        &.btn-round {
            background-color: var(--color-white);
            color: var(--color-heading) !important;
            border-radius: 30px;
            &:hover {
                color: var(--color-white) !important;
            }
        }
    }
}