/*----------------------
    Animation  
-----------------------*/

// Mouse Move/Particle/Abstract Shape Animation
.edublink-animated-shape {
    position: relative;
    z-index: 9;
}

.shape-group {
    @extend %liststyle;
    li {
        margin: 0;
        position: absolute;
        z-index: -1;
    }
    @media #{$smlg-device} {
        display: none;
    }
}

.z-10 {
    z-index: 10;
}

// Header Sticky Animation

@keyframes headerSticky {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes rotateIt {
    to {
        transform: rotate(-360deg);
    }
}

@keyframes bounceSlide {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
}

.bounce-slide {
    animation-duration: 2.5s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    animation-name: bounceSlide;
}

@keyframes line1 {
    0% {
        opacity: 1;
        bottom: 0
    }
    30% {
        bottom: 30%
    }
    50% {
        bottom: 50%
    }
    70% {
        bottom: 70%
    }
    100% {
        bottom: 100%;
        opacity: 0.5;
    }
}

@keyframes line2 {
    0% {
        opacity: 1;
        top: 0
    }
    30% {
        bottom: 30%
    }

    50% {
        bottom: 50%
    }

    70% {
        bottom: 70%
    }
    
    100% {
        top: 100%;
        opacity: 0.5;
    }
}

@keyframes line3 {
    0% {
        opacity: 1;
        top: 500px
    }
    50% {
        top: 50%
    }
    100% {
        top: 100%;
        opacity: 0.5;
    }
}

@keyframes line4 {
    0% {
        opacity: 1;
        top: 300px;
    }
    50% {
        top: 50%
    }
    100% {
        top: 100%;
        opacity: 0.5;
    }
}

@keyframes line5 {
    0% {
        opacity: 1;
        bottom: 400px
    }
    50% {
        bottom: 50%
    }
    100% {
        bottom: 100%;
        opacity: 0.5;
    }
}

@keyframes customOne {
    from {
        transform: scale(1);
    }

    50% {
        transform: scale(0.90);
    }

    to {
        transform: scale(1);
    }
}

@keyframes customTwo {
    0% {
        transform: (translate(0.0px, 0.0px));
    }

    50% {
        transform: (translate(100.0px, 0.0px));
    }

    100% {
        transform: (translate(50.0px, 50.0px));
    }
}

.customOne {
    animation: customOne 2s infinite;
}
.rotateit {
    animation: rotateIt 11s linear infinite;
}

@-webkit-keyframes edublink-vsm-y-move {
    0% {
        -webkit-transform: translate(0, -30%);
        transform: translate(0, -30%);
    }
    100% {
        -webkit-transform: translate(0, 30%);
        transform: translate(0, 30%);
    }
}

@keyframes edublink-vsm-y-move {
    0% {
        -webkit-transform: translate(0, -30%);
        transform: translate(0, -30%);
    }
    100% {
        -webkit-transform: translate(0, 30%);
        transform: translate(0, 30%);
    }
}

@-webkit-keyframes edublink-vsm-y-reverse-move {
    0% {
        -webkit-transform: translate(0, 30%);
        transform: translate(0, 30%);
    }
    100% {
        -webkit-transform: translate(0, -30%);
        transform: translate(0, -30%);
    }
}

@keyframes edublink-vsm-y-reverse-move {
    0% {
        -webkit-transform: translate(0, 30%);
        transform: translate(0, 30%);
    }
    100% {
        -webkit-transform: translate(0, -30%);
        transform: translate(0, -30%);
    }
}

@-webkit-keyframes edublink-sm-x-move {
    0% {
        -webkit-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
    }
    100% {
        -webkit-transform: translate(50%, 0);
        transform: translate(50%, 0);
    }
}

@keyframes edublink-sm-x-move {
    0% {
        -webkit-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
    }
    100% {
        -webkit-transform: translate(50%, 0);
        transform: translate(50%, 0);
    }
}

@-webkit-keyframes edublink-sm-x-reverse-move {
    0% {
        -webkit-transform: translate(50%, 0);
        transform: translate(50%, 0);
    }
    100% {
        -webkit-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
    }
}

@keyframes edublink-sm-x-reverse-move {
    0% {
        -webkit-transform: translate(50%, 0);
        transform: translate(50%, 0);
    }
    100% {
        -webkit-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
    }
}

@-webkit-keyframes edublink-rotate-y {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
    }
}

@-webkit-keyframes edublink-rotate-x {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes edublink-rotate-x {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes edublink-rotate-y {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
    }
}

@-webkit-keyframes edublink-zoom1 {
    0% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }
    100% {
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
    }
}

@keyframes edublink-zoom2 {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    100% {
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
    }
}

@-webkit-keyframes edublink-zoom1 {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    100% {
        -webkit-transform: scale(1.25);
        transform: scale(1.25);
    }
}

@keyframes edublink-zoom1 {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    100% {
        -webkit-transform: scale(1.25);
        transform: scale(1.25);
    }
}



/* ------------------------
    Custom Animation 01 
----------------------------*/

@keyframes headerSlideDown {
    0% {
        margin-top: -100px
    }

    to {
        margin-top: 0
    }
}


/*------------------------
	slidefadeinup
--------------------------*/

@keyframes slideFadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 20%, 0);
        transform: translate3d(0, 20%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

.slideFadeInUp {
    -webkit-animation-name: slideFadeInUp;
    animation-name: slideFadeInUp;
}

/* -----------------------------------
    Custom Animation For All Page
---------------------------------------*/

@keyframes moveVertical {
    to {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}


/*--------------------------------
Scroll Down Button Animation  
----------------------------------*/
@keyframes scrollDown {
    0% {
        opacity: 0;
    }

    10% {
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        transform: translateY(10px);
        opacity: 0;
    }
}


@keyframes ripple {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  75% {
    -webkit-transform: scale(1.75);
    transform: scale(1.75);
    opacity: 1; }
  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0; } 
}

@keyframes ripple_2 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  75% {
    -webkit-transform: scale(3);
    transform: scale(3);
    opacity: 1; }
  100% {
    -webkit-transform: scale(4);
    transform: scale(4);
    opacity: 0; } 
}

@keyframes ripple_3 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  50% {
    -webkit-transform: scale(1.15);
    transform: scale(1.15);
    opacity: 1; }
  100% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
    opacity: 0; } 
}


@keyframes radiusChange {
    0% {
        border-radius: 0px 0px 0px 0px;
    }

    40% {
        border-radius: 50px 0px 100px 0px;
    }

    80% {
        border-radius: 0px 50px 0px 100px;
    }

    100% {
        border-radius: 0px 0px 0px 0px;
    }
}

@keyframes radiusChange2 {
    0% {
        border-radius: 0px 0px 0px 0px;
    }

    25% {
        border-radius: 50px 0px;
    }

    50% {
        border-radius: 0px 0px 0px 0px;
    }

    75% {
        border-radius: 0px 50px;
    }

    100% {
        border-radius: 50px 0px;
    }
}
@keyframes edu-wave {
    0% {
        transform: translateX(0) translateZ(0) scaleY(1)
    }
    50% {
        transform: translateX(-25%) translateZ(0) scaleY(0.55)
    }
    100% {
        transform: translateX(-50%) translateZ(0) scaleY(1)
    }
}

@keyframes wave {
    0% {
        margin-left: 0;
    }
    100% {
        margin-left: -1600px;
    }
}
  
  @keyframes swell {
    0%, 100% {
      transform: translate3d(0,-25px,0);
    }
    50% {
      transform: translate3d(0,5px,0);
    }
}

@keyframes scroll {
    0% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        transform: translateX(0);
    }
    to {
        -webkit-transform: translateX(-webkit-calc(-250px * 7));
        -moz-transform: translateX(-moz-calc(-250px * 7));
        transform: translateX(-1750px);
    }
}
@keyframes eduBlinkTestimonialScroll {
    from {
        transform: translateY(0)
    }
    to {
        transform: translateY(-50%)
    }
}