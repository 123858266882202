.faq-style-8 {
    .shape-group {
        li {
            &.shape-1 {
                left: 19%;
                top: -14px;
                transform: translateX(0%);
            }
        }
    }
}