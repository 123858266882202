/*-------------------
  Hero Banner Styles
---------------------*/
.expert-area-1 {
    background-color: #f5f5f5;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: url(../../../images/bg/bg-image-53.webp);
    }
    .expert-section-heading {
        .section-title {
            p {
                width: 525px;
                @media #{$sm-layout} {
                    width: 100%;
                }
            }
        }
    }
    .expert-progressbar {
        padding-left: 35px;
        @media #{$sm-layout} {
            padding-left: 0;
        }
        .single-progressbar {
            // margin-bottom: 30px;
            &.last-ber {
                margin-bottom: 0;
            }
      
            .progress-title {
                color: var(--color-black);
                font-size: 16px;
                text-transform: capitalize;
                margin-bottom: 0px;
            }
            .progressbar {
              width: 100%;
              margin-top: 5px;
              margin-bottom: 20px;
              position: relative;
        
                &::before {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 5px;
                    border-radius: 5px;
                    background-color: #e6e6e6;
                    z-index: -1;
                }
            }
            .proggress {
              height: 5px;
              width: 10px;
              background: var(--gradient-primary);
            }
            .percentCount {
              float: right;
              margin-top: -53px;
              clear: both;
              font-size: 16px;
              font-family: var(--font-primary);
              color: var(--color-black);
            }
        }
    }

}