/*-------------------
  Benefits Styles
---------------------*/
.benefits-area-1 {
    .benefits-wrapper {
        height: 730px;
        display: flex;
        overflow: hidden;
        position: relative;
        @media #{$md-layout-1} {
            display: block;
            height: 100%;
        }

        .benefits-wrap-one {
            background-color: #F0F4F5;
            width: 50%;
            @media #{$md-layout-1} {
                width: 100%;
            }
        }
        .benefits-wrap-two {
            width: 50%;
            position: relative;
            z-index: 12;
            @media #{$md-layout-1} {
                width: 100%;
            }
            .process-gallery {
                .gallery-thumbnail {
                    .thumbnail {
                        img {
                            width: 100%;
                            height: 730px;
                            background-position: center;
                            background-repeat: no-repeat;
                            object-fit: cover;
                        }
                    }
                }
            } 
        }
        .benefits-content-box {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            @media #{$md-layout-1} {
                position: relative;
                left: inherit;
                right: inherit;
                top: inherit;
                bottom: inherit;
            }

            .process-content {
                padding: 110px 0 120px;
                position: relative;
                @media #{$md-layout-1} {
                    padding: 55px 0 30px;
                }
        
                .section-title {
                    margin-bottom: 10px;
                    p {
                        max-width: 400px;
                        margin-bottom: 0;
                        @media #{$md-layout-1} {
                           padding-right: 30px;
                           max-width: 100%;
                        }
                    }
                }
                .features-wrapper {
                    display: flex;
                    gap: 65px;
                    @media #{$small-mobile} {
                        display: block;
                    }
        
                    .features-list {
                        list-style: none;
                        padding-left: 0;
                        margin-bottom: 0;
                        li {
                            font-weight: var(--p-medium);
                            font-family: var(--font-secondary);
                            color: var(--color-heading);
                            padding-left: 25px;
                            position: relative;
                            margin-bottom: 16px;
                            margin-top: 16px;
                            &::before {
                                content: "\e913";
                                font-family: 'icomoon';
                                color: var(--color-secondary);
                                font-size: 19px;
                                position: absolute;
                                left: 0;
                                top: -3px;
                            }
                        }
                    }
                }
                .features-btn {
                    margin-top: 25px;
                }
                .shape-group {
                    li {
                        &.shape-1 {
                            left: -447px;
                            top: -137px;                        
                            span {
                                border: 50px solid var(--color-white);
                                border-radius: 50%;
                                width: 395px;
                                height: 395px;
                                display: inline-block;
                            }
                        }
                        &.shape-2 {
                            left: -286px;
                            top: 167px;            
                        }
                        &.shape-3 {
                            right: -88px;
                            bottom: 70px;          
                        }
                    }
                }
            }
        }
    }
}