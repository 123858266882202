/*-------------------
  Why Choose Styles
---------------------*/
.why-choose-area-5 {
    .why-choose-gallery {
        .thumbnail {
            &.thumbnail-1 {
                text-align: left;
                position: relative;
                z-index: -1;
                img {
                    border-radius: 0;
                    border-top-left-radius: 230px;
                    border-top-right-radius: 230px;
                    border-bottom-left-radius: 200px;
                }
            }
        }
        .video-lesson {
            margin-top: -107px;
            display: flex;
            justify-content: right;
            @media #{$sm-layout} {
                display: none;
            }

            .inner {
                width: 200px;
                height: 200px;
                padding: 68px 0px;
                border-radius: 50%;
                display: inline-block;
                background-color: rgb(248, 148, 31);
                box-shadow: 0px 10px 70px 0px rgba(14, 4, 29, 0.2);
                position: relative;
                text-align: center;

                &::after {
                    content: "";
                    position: absolute;
                    top: -20px;
                    left: -20px;
                    width: 100%;
                    height: 100%;
                    background: var(--color-white);
                    border-radius: 50%;
                    z-index: -1;
                }
                .content {
                    .title {
                        font-size: 18px;
                        line-height: 32px;
                        color: var(--color-white);
                        margin-bottom: 0;
                        .number {
                            font-size: 26px;
                            font-weight: bold;
                        }
                    }
                }
            }
        }
        .shape-group {
            li {
                &.shape-1 {
                    left: 16px;
                    top: 14px;
                    z-index: -2;
                }
                &.shape-3 {
                    left: -19px;
                    top: 54px;
                }
            }
        }
    }
    .why-choose-content {
        margin-top: 80px;
        .features-list {
            list-style: none;
            padding-left: 0;
            li {
                color: var(--color-heading);
                font-size: 18px;
                font-weight: var(--p-semi-bold);
                font-family: var(--font-secondary);
            }
        }
    }
}