/*-------------------
      Faq Styles
---------------------*/
.faq-style-12 {
    background-color: #f5f5f5;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: url(../../../images/bg/bg-image-53.webp);
    }
    .faq-accordion {
        .accordion-item {
            .accordion-button {
                border-radius: 5px 5px 0 0;
                background: linear-gradient(90deg, #1AB69D 0%, #31B978 118.72%);

                &.collapsed {
                    background: var(--color-white);
                    color: var(--color-heading);
                    border-radius: 5px;
                    &::before {
                        color: var(--color-body);
                    }
                }
            }
            .accordion-collapse {
                &.collapse {
                    .accordion-body {
                        border-radius: 0 0 5px 5px;
                    }
                }
            }
        }
    }
    .edu-faq-gallery {
        .faq-thumbnail {
            
            img {
                border-radius: 5px;
            }
            &.thumbnail-1 {
                position: relative;
                z-index: 1;
                bottom: -10px;
            }
            &.thumbnail-2 {
                position: relative;
                z-index: 1;
                margin-top: -215px;
                @media #{$sm-layout} {
                    display: none !important;
                }    
            }
        }
        .shape-group {
            li {
                &.shape-1 {
                    top: -1px;
                    left: -10px;
                    z-index: 0;
                }
                &.shape-2 {
                    top: -45px;
                    left: 39px;
                    right: inherit;
                    z-index: 1;
                }
            }
        }
    }
}