.custom-time{
    display: flex;
    justify-content: left;
    align-items: flex-end;
  }
  .time-table{
    border-collapse: collapse;
    border-spacing: 0;
    margin: 0 0 0;
    width: 100% !important;
    margin-top: 15px;
  }

  .days{
    font-size: 14px;
    text-align: left;
    margin-top: 20px;
  }
  .table-head{
    font-size: 28px !important;
    font-weight: bold !important;
    text-align: center;
  }
  .table-items{
    color: var(--color-white);
    // background: white !important;
    text-align: center !important;
  }
  .table-items-head{
    padding-top: 10px;
  }

.breadcrumb-style-3 {
    padding: 5px 0 0px;
}

.user-profile{
  width: 100%;
}