.edu-session {
    background-color: var(--color-secondary);
    position: relative;
    border-radius: 5px;
    transition: var(--transition);
    &.course-style-1 {
        transition: var(--transition-2);
        .thumbnail {
            transition: var(--transition-2);
            img {
                transition: var(--transition-2);
            }
            &:after {
                transition: var(--transition-2);
            }
        }
        .course-hover-content-wrapper {
            transition: var(--transition-2);
        }
        .course-hover-content {
            transition: var(--transition-2);
            p {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }
        }
    }

    .thumbnail {
        position: relative;
        transition: var(--transition);
        a {
            display: block;
            overflow: hidden;
            border-radius: 5px 5px 0 0;
            img {
                border-radius: 5px 5px 0 0;
                transition: 0.4s;
                width: 100%;
            }
            &:after {
                content: "";
                height: 100%;
                width: 100%;
                background-color: rgba(0,0,0,.20);
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                transition: var(--transition);
                border-radius: 5px 5px 0 0;
            }
        }
        .time-top {
            position: absolute;
            top: 10px;
            right: 10px;
        }
        .duration {
            background-color: #f8b81f;
            border-radius: 3px;
            padding: 4px 8px;
            font-weight: 500;
            font-size: 13px;
            color: var(--color-white);
            font-family: var(--font-secondary);
            display: block;
            i {
                font-size: 16px;
                position: relative;
                top: 2px;
                margin-right: 6px;
            }
        }
    }
    .content {
        padding: 30px 20px 16px 30px;
        .course-level {
            font-size: 13px;
            font-weight: 500;
            color: var(--color-primary);
            font-family: var(--font-secondary);
            background-color: rgba(250, 250, 250, 1);
            border-radius: 3px;
            padding: 1px 10px;
            margin-bottom: 15px;
            display: inline-block;
        }
        .title {
            margin-bottom: 8px;
            a {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                color: white;
            }
        }
        .course-rating {
            display: flex;
            align-items: center;
            margin-bottom: 8px;
            .rating {
                color: #f8b81f;
                transition: var(--transition);
            }
            .rating-count {
                font-size: 14px;
                font-weight: 500;
                color: var(--color-heading);
                margin-left: 10px;
                transition: var(--transition);
            }
        }
        .course-price {
            font-size: 14px;
            font-weight: var(--p-semi-bold);
            color: var(--color-secondary);
            font-family: var(--font-secondary);
            margin-bottom: 14px;
            transition: var(--transition);
            &.price-round {
                font-size: 22px;
                font-weight: var(--p-bold);
                color: var(--color-white);
                height: 80px;
                width: 80px;
                line-height: 84px;
                background-color: var(--color-primary);
                border-radius: 50%;
                text-align: center;
            }
        }
        .course-meta {
            @extend %liststyle;
            li {
                display: inline-block;
                font-size: 13px;
                color: var(--color-heading);
                margin-right: 25px;
                position: relative;
                transition: var(--transition);
                &:after {
                    content: "";
                    height: 19px;
                    width: 1px;
                    background-color: #e5e5e5;
                    position: absolute;
                    top: 1px;
                    right: -15px;
                    transition: var(--transition);
                }
                &:last-child {
                    margin-right: 0;
                    &:after {
                        display: none;
                    }
                }
                i {
                    color: #a7a7a7;
                    font-size: 14px;
                    margin-right: 7px;
                    transition: var(--transition);
                }
            }
        }
    }
    .course-hover-content-wrapper {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        transition: var(--transition);
        opacity: 0;
        background-color: var(--color-primary);
        button {
            &.wishlist-btn {
                &:hover {
                    background: var(--color-secondary);
                }
                background: rgba(255, 255, 255, 0.15);
                position: absolute;
                top: 20px;
                right: 20px;
                display: none;
                @media #{$smlg-device} {
                    display: block;
                }
            }
        }
    }
    .course-hover-content {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 4px;
        padding: 50px 20px 45px 30px;
        visibility: hidden;
        opacity: 0;
        transition: var(--transition);
        display: inline-table;
        .content {
            padding: 0;
            .wishlist-btn {
                &:hover {
                    background: var(--color-secondary);
                }
                background: rgba(255, 255, 255, 0.15);
                position: absolute;
                top: 10px;
                right: 10px;
                @media #{$smlg-device} {
                    display: none;
                }
            }
            .course-level {
                background-color: var(--color-white);
                color: var(--color-heading);
            }
            .title {
                color: var(--color-white);
                a {
                    &:hover {
                        color: var(--color-white);
                    }
                }
            }
            .course-rating {
                .rating-count {
                    color: var(--color-white);
                }
            }
            .course-price {
                color: var(--color-white);
            }
            p {
                color: var(--color-white);
                margin-bottom: 15px;
            }
            .course-meta {
                margin-bottom: 16px;
                li {
                    color: var(--color-white);
                    &:after {
                        background-color: rgba(255,255,255,.25);
                    }
                    i {
                        color: var(--color-white);
                    }
                }
            }
        }
    }

    .hover-content-aside {
        width: 344px;
        padding-left: 12px;
        position: absolute;
        top: 50%;
        left: 100%;
        transform: translateY(-50%) translateX(-20px);
        visibility: hidden;
        opacity: 0;
        transition: var(--transition);
        pointer-events: none;
        z-index: 1;
        .content {
            background-color: var(--color-white);
            border-radius: 4px;
            box-shadow: 0px 0px 50px 0px rgba(26,46,85,0.2);
            padding: 30px 30px 40px 30px;
            &:before {
                content: "";
                border-top: 12px solid transparent;
                border-right: 13px solid var(--color-white);
                border-bottom: 12px solid transparent;
                position: absolute;
                top: 50%;
                left: -13px;
                transform: translateY(-50%);
            }
            .course-rating {
                margin-bottom: 0;
            }
            .course-meta {
                margin-bottom: 5px;
                li {
                    font-size: var(--font-size-b1);
                    color: var(--color-body);
                    &:after {
                        height: 5px;
                        width: 5px;
                        background-color: #d1d1d1;
                        border-radius: 50%;
                        top: 50%;
                        transform: translateY(-50%);
                        right: -17px;
                    }
                }
            }
            .course-feature {
                margin-bottom: 25px;
                ul {
                    @extend %liststyle;
                    li {
                        font-size: 13px;
                        position: relative;
                        padding-left: 28px;
                        &:before {
                            content: "\e913";
                            font-family: 'icomoon';
                            color: var(--color-heading);
                            margin-right: 15px;
                            position: absolute;
                            left: 0;
                            top: 2px;
                        }
                    }
                }
            }
           .button-group {
                .edu-btn {
                    flex: 1;
                }
            }
        }
        &.content-right {
            left: auto;
            right: 100%;
            padding-left: 0;
            padding-right: 12px;
            .content {
                &:before {
                    left: auto;
                    right: -12px;
                    transform: scaleX(-1);
                }
            }
        }
    }
    &:hover {
        .course-hover-content-wrapper {
            opacity: 1;
        }
        .thumbnail {
            a {
                img {
                    transform: scale(1.1);
                }
            }
        }
        .course-hover-content {
            visibility: visible;
            opacity: 1;
        }
        .hover-content-aside {
            pointer-events: all;
            visibility: visible;
            opacity: 1;
            transform: translateY(-50%) translateX(0);
        } 
    }
    &.course-box-shadow {
        box-shadow: var(--shadow-darker);
    }
    &.course-style-1.hover-button-bg-white,
    &.course-style-2.hover-button-bg-white {
        a.edu-btn.btn-secondary{
            &:hover {
                color: var(--color-black);
                background: #ffffff;
                &:after {
                    background: #ffffff;
                }
            }
            &:after {
                display: none;
            }
        }
    } 
}