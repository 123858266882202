html[dir="rtl"]{
    .header-top {
       .header-top-right {
            .header-info {
                li {
                    padding: 14px 35px 12px 30px;
                    @media #{$lg-layout} {
                        padding: 14px 30px 12px;
                        font-size: 11px;
                    }
                    .contact {
                        position: relative;
                        margin: 0 2px 0 0;
                        transition: var(--transition);
                        
                        &:hover {
                            color: var(--color-secondary);
                        }
                    }
                    .icon-phone,
                    .icon-envelope {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        right: -30px;
                        top: 10%;
                    }
                    &.header-btn {
                        padding: 0 0 0 30px !important;
                        .edu-btn {
                            i {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
       } 
       .header-top-left {
            .header-notify {
                @media #{$lg-layout} {
                    font-size: 11px;
                }
            }
       }
       .header-info {
            li {
                .icon-envelope,
                .icon-phone {
                    padding-right: inherit;
                    padding-left: 10px;
                }
                &::after {
                    right: inherit;
                    left: 0;
                }
            }
       }
       
    }
    .header-action li.icon.cart-icon {
        margin-right: 10px;
    }
    .header-category .mainmenu-nav .mainmenu li {
        margin-right: 0;
    }
    .edu-header .header-category .mainmenu li.has-droupdown > a i {
        padding-right: inherit;
        margin-left: 10px;
    }
    .edu-header .header-category .mainmenu li.has-droupdown > a {
        padding: 0 20px 0 40px;
    }
    .edu-header .header-brand .logo {
        margin-right: 0;
        margin-left: 40px;
    }
    .header-style-4 .header-navbar .header-mainnav {
        margin-left: 0;
        margin-right: 25px;
    }
    .header-style-4 .mainmenu-nav .mainmenu li ul.mega-menu {
        right: -145px;
    }
    .header-style-2 .mainmenu-nav .mainmenu li ul.mega-menu, 
    .header-style-3 .mainmenu-nav .mainmenu li ul.mega-menu {
        right: -500px;
    }
    .header-style-7 .mainmenu-nav .mainmenu li ul.mega-menu {
        left: inherit;
        right: -210px;
    }
    .header-style-2 {
        .header-mainmenu {
            .header-right {
                padding-left: inherit;
                padding-right: 50px;
                margin-left: 10px;

                &::after {
                    left: inherit;
                    right: 17px;
                }
            }
        }
    }
    .header-style-1  {
        .header-brand {
            .logo {
                margin-right: 27px;
                @media #{$sm-layout} {
                    margin-right: 0;
                }
            }
        }
        .header-category {
            margin-right: 30px;
        }
    }
    .header-style-3 {
        .header-brand {
            .logo {
                margin-right: 0;
            }
        }
        .header-mainmenu {
            .header-right {
                padding-left: inherit;
                padding-right: 50px;
                margin-left: inherit;
                margin-right: 22px;

                &::after {
                    left: inherit;
                    right: 0;
                }
            }
        }
        .header-action {
            margin: 0;
        }
    }
    .header-style-7 .header-brand .logo {
        margin-right: 0;
        margin-left: 40px;
    }
    .header-action {
        .search-bar {
            .input-group {
                .form-control {
                    padding: 0 20px 0 20px;
                }
            }
        }
        li {
            &.icon {
                &.cart-icon {
                    transform: rotateY(180deg);
                }
            }
        }
    }
    .mainmenu-nav {
        .mainmenu {
            li {
                &.has-droupdown {
                    > a {
                        &::after {
                            right: inherit;
                            left: 10px;
                        }
                    }
                    .submenu {
                        text-align: right;
                        left: inherit;
                        right: 0;
                    }
                }
                ul {
                    &.mega-menu {
                        left: inherit;
                        right: -292px;

                        .menu-title {
                            padding-left: 0;
                            padding-right: 70px;
                        }
                        .submenu {
                            padding: 0px 35px 0 0;
                        }

                        > li {
                            border-right: 1px solid var(--color-border);
                            &:first-child {
                                border-right: 0;
                            }
                        }
                    }
                    &.mega-menu-one {
                        > li {
                            border-right: 1px solid var(--color-border);
                            &:nth-child(3) {
                                border-right: none;
                            }
                        }
                    }
                }
            }
        }
    }
    .popup-mobile-menu {
        .inner {
            left: inherit;
            right: -150px;
        }
        .mainmenu {
            .has-droupdown {
                > a {
                    &::after {
                        right: inherit;
                        left: 0;
                    }
                }
            }
        }
        &.active {
            .inner {
                left: inherit;
                right: 0;
            }
        }
    }
    .edu-search-popup {
        .site-logo {
            left: inherit;
            right: 70px;
        }
        .close-button {
            right: inherit;
            left: 62px;
        }
        .inner {
            .search-form {
                .submit-button {
                    right: inherit;
                    left: 0;
                }
            }
        }
    }

    // Hero area

    .hero-banner {
        .banner-content {
            .shape-group {
                li {
                    &.shape-1 {
                        left: inherit;
                        right: -74px;
                    }
                }
            }
        }
        .banner-thumbnail {
            .thumbnail {
                text-align: left;
            }

            .instructor-info {
                left: inherit;
                right: 0;

                .inner {
                    padding: 26px 20px 16px 30px;
                    .title {
                        margin-right: 14px;
                    }
                    .media {
                        .content {
                            margin-right: 10px;
                        }
                    }
                }
            }
            .shape-group {
                li {
                    &.shape-1 {
                        left: inherit;
                        right: 0;
                    }
                    &.shape-2 {
                        left: inherit;
                        right: 0;
                    }
                    &.shape-3 {
                        left: inherit;
                        right: 0;
                    }
                    &.shape-4 {
                        right: inherit;
                        left: 41px;
                    }
                    &.shape-5 {
                        right: inherit;
                        left: -50px;
                    }
                    &.shape-6 {
                        right: inherit;
                        left: -98px;
                    }
                }
            }
        }
        &.hero-style-1 {
            .shape-7 {
                right: inherit;
                left: 0;
            }
        }
        &.hero-style-2 {
            .banner-gallery {
                .thumbnail {
                    &.thumbnail-2 {
                        padding-left: inherit;
                        padding-right: 30px;

                        @media #{$md-layout} {
                            text-align: right;
                        }
                    }
                }
                .online-support {
                    left: inherit;
                    right: 85px;
                    .inner {
                        .icon {
                            margin-right: inherit;
                            margin-left: 15px;
                        }
                        .content {
                            text-align: right;
                        }
                    }
                }
                .shape-group {
                    li {
                        &.shape-1 {
                            right: inherit;
                            left: 115px;
                        }
                        &.shape-2 {
                            right: inherit;
                            left: -50px;
                        }
                        &.shape-3 {
                            right: inherit;
                            left: -108px;
                        }
                        &.shape-4 {
                            left: inherit;
                            right: -12px;
                        }
                    }
                }
            }
        }
        &.hero-style-3 .swiper-slide:before { 
            background: linear-gradient(270deg, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0) 80%);
        }
        &.hero-style-3 .thumbnail-bg-content .banner-content {
            right: 0;
            left: inherit;
        }
        &.hero-style-3 .shape-group li.shape-1 {
            left: inherit;
            right: 8.2%;
        }
        &.hero-banner.hero-style-3 .shape-group li.shape-2 {
            left: inherit;
            right: 26%;
        }
        &.hero-style-3 .shape-group li.shape-3 {
            left: inherit;
            right: -153px;
        }
        &.hero-style-3 .hero-slider-bg-controls {
            right: inherit;
            left: 70px;
        }
        &.hero-style-3 .hero-slider-bg-controls .slide-prev {
            right: inherit;
            left: -70px;
        }
        &.hero-style-4 {
            .banner-gallery {
                .online-support {
                    .inner {
                        .content {
                            text-align: right;
                        }
                        .icon {
                            margin-right: inherit;
                            margin-left: 15px;
                        }
                    }
                }
            }
        }
        &.hero-style-5 {
            .banner-thumbnail {
                .shape-group {
                    .shape-2 {
                        left: inherit;
                        right: -22px;
                    }
                    .shape-3 {
                        right: inherit;
                        left: -16px;
                    }
                }
            }
        }
        &.hero-style-6 {
            .banner-thumbnail {
                .shape-group {
                    .shape-1 {
                        right: inherit;
                        left: 0;
                    }
                    .shape-2 {
                        left: inherit;
                        right: 80px;
                    }
                    .shape-3 {
                        right: inherit;
                        left: -75px;
                    }
                    .shape-4 {
                        right: inherit;
                        left: 23px;
                    }
                }
            }
            .shape-group {
                li {
                    &.shape-1 {
                        left: inherit;
                        right: 250px;
                    }
                    &.shape-2 {
                        left: inherit;
                        right: 115px;
                    }
                    &.shape-3 {
                        right: inherit;
                        left: 0;
                    }
                }
            }
        }
        &.hero-style-8 {
            .banner-content {
                text-align: right;
                @media #{$md-layout-1} {
                    text-align: center;
                }
                .title {
                    @media #{$md-layout-1} {
                        padding: 0 110px;
                    }
                    @media #{$large-mobile} {
                        padding: 0 60px;
                    }
                }
                
                p {
                   padding-right: inherit;
                   padding-left: 90px; 
                   @media #{$md-layout-1} {
                    padding-left: 0;
                }
                }
                .banner-search {
                    .input-group {
                        .form-control {
                            padding: 0 20px;
                        }
                    }
                }
            }
            .shape-group {
                li {
                    &.shape-5 {
                        left: inherit;
                        right: -240px;
                    }
                    &.shape-6 {
                        left: inherit;
                        right: -297px;
                    }
                }
            }
            .banner-thumbnail {
                margin-right: inherit;
                margin-left: -100px;
                @media #{$custom-lg-device-eight} {
                    margin-left: -40px;
                }
                @media #{$smlg-device} {
                    margin-left: 0;
                }
                .instructor-info {
                    right: 70px;
                }
                .shape-group {
                    li {
                        &.shape-1 {
                            right: 162px;
                        }
                        &.shape-2 {
                            right: inherit;
                            left: -30px;
                        }
                        &.shape-3 {
                            right: inherit;
                            left: -100px;
                            @media #{$custom-lg-device-six} {
                                left: -40px;
                            }
                        }
                        &.shape-4 {
                            left: inherit;
                            right: -10px;
                        }
                    }
                }
            }
        }
    }
    .hero-style-9 .slider .health-slider-content {
        margin-right: 0;
    }
    .hero-style-9 .health-slider-main {
        left: inherit;
        right: calc(60% - 80px);
    }
    .hero-style-9 .hero-slider-bg-controls .slide-prev {
        right: 70px;
        top: 0;
    }
    .hero-style-9 .hero-slider-bg-controls {
        left: inherit;
        right: 0;
    }
    .hero-style-9 .slider .container .shape-group li.shape-1 {
        left: inherit;
        right: -460px;
    }
    .hero-style-9 .slider .container .shape-group li.shape-2 {
        left: inherit;
        right: -257px;
    }
    .hero-style-9 .shape-group li.shape-3 {
        left: inherit;
        right: 39%;
    }
    .hero-style-9 .shape-group li.shape-4 {
        left: inherit;
        right: 100px;
        @media #{$custom-lg-device-six} {
            right: 2px;
        }
    }
    .hero-style-9 .shape-group li.shape-5 {
        right: inherit;
        left: 38.5%;
    }
    .hero-style-9 .shape-group li.shape-6 {
        right: inherit;
        left: 42.5%;
    }
    .hero-style-10 .banner-content p {
        padding-right: inherit;
        padding-left: 85px;
        @media #{$md-layout-1} {
            padding-left: 0;
        }
    }
    .hero-style-10 .banner-content .shape-group li.shape-1 {
        left: 106%;
        right: inherit;
    }
    .hero-style-10 .banner-content .shape-group li.shape-2 {
        left: inherit;
        top: -18px;
        right: -209px;
    }
    .hero-style-10 .shape-group li.shape-8 {
        right: inherit;
        left: 0;
    }
    .hero-style-10 .banner-thumbnail {
        margin-right: 0;
        margin-bottom: -170px;
        margin-left: -190px;
        @media #{$smlg-device} {
           margin-left: -100px; 
        }
        @media #{$md-layout-1} {
            margin-left: -13px;
            margin-bottom: 0; 
        }
        @media #{$large-mobile} {
            margin-bottom: -15px;
            margin-left: 0; 
        }
    }
    .hero-style-10 .banner-thumbnail .instructor-info {
        bottom: 210px;
        left: inherit;
        right: -1px;
    }
    .hero-style-10 .banner-thumbnail .instructor-info .inner {
        padding: 26px 40px 44px;
    }
    .hero-style-10 .banner-thumbnail .shape-group li.shape-3 {
        top: 0;
        left: inherit;
        right: -20%;
    }
    .hero-style-10 .banner-thumbnail .shape-group li.shape-4 {
        top: -20px;
        left: inherit;
        right: -100px;
    }
    .hero-style-10 .banner-thumbnail .shape-group li.shape-5 {
        right: inherit;
        left: 65px;
    }
    .hero-style-10 .banner-thumbnail .shape-group li.shape-6 {
        right: inherit;
        left: 110px;
    }
    .hero-style-10 .banner-thumbnail .shape-group li.shape-7 {
        right: inherit;
        bottom: 215px;
        left: 171px;
    }
    .hero-style-11 .banner-content p {
        padding-left: 20px;
    }
    .hero-style-11 .banner-content .shape-group li.shape-1 {
        left: inherit;
        right: -218px;
    }
    .hero-style-11 .banner-thumbnail .shape-group li.shape-2 {
        left: inherit;
        right: -72px;
    }
    .hero-style-11 .banner-thumbnail .shape-group li.shape-3 {
        right: 100px;
        bottom: -60px;
        top: inherit;
        left: inherit;
    }
    .hero-style-11 .banner-thumbnail .shape-group li.shape-4 {
        left: -106px;
        right: inherit;
    }
    .hero-style-11 .banner-thumbnail .shape-group li.shape-5 {
        left: -134px;
        bottom: inherit;
        right: inherit;
    }
    .hero-style-13 .banner-thumbnail .shape-group li.shape-1 {
        top: -95px;
        left: -71px;
        right: inherit;
    }
    .hero-style-13 .banner-thumbnail .shape-group li.shape-2 {
        top: -24px;
        left: 7px;
        right: inherit;
    }
    .hero-style-13 .banner-thumbnail .shape-group li.shape-3 {
        left: inherit;
        right: -73px;
    }
    .hero-style-13 .banner-thumbnail .shape-group li.shape-4 {
        right: 0;
        left: inherit;
    }
    .hero-style-13 .shape-group li.shape-8 {
        left: -7px;
    }
    .hero-style-12.hero-banner .banner-thumbnail {
        margin-right: inherit;
        margin-left: -366px;
        @media #{$custom-lg-device-six} {
            margin-left: -240px;
        }
        @media #{$custom-laptop-device} {
            margin-left: -152px;
        }
        @media #{$lg-layout} {
            margin-left: -132px;
            margin-top: 0;
        }
        @media #{$md-layout-1} {
            margin-left: 0;
            margin-top: 0;
        }
    }
    .hero-style-12.hero-banner .edublink-animated .shape-group li.shape-1 {
        left: inherit;
        right: -437px;
    }
    .hero-style-12.hero-banner .edublink-animated .shape-group li.shape-2 {
        left: inherit;
        right: -270px;
    }
    .hero-style-12.hero-banner .edublink-animated .shape-group li.shape-3 {
        left: inherit;
        top: 38px;
        right: -110px;
    }
    .hero-style-12.hero-banner .edublink-animated .shape-group li.shape-4 {
        right: inherit;
        left: 0;
    }
    .hero-style-12.hero-banner .shape-group li.shape-5 {
        right: inherit;
        left: 34%;
    }
    .hero-style-12.hero-banner .shape-group li.shape-6 {
        right: inherit;
        left: 32%;
    }
    .hero-style-12.hero-banner .banner-content .banner-btn-video {
        justify-content: right;
        @media #{$md-layout-1} {
           justify-content: center; 
        }
    }
    .hero-style-12.hero-banner .banner-content .banner-btn-video .video-btn {
        margin-left: inherit;
        margin-right: 20px;
    }
    .hero-style-12.hero-banner .banner-content .banner-btn-video .video-btn span {
        padding-left: inherit;
        padding-right: 10px;
    }
    .hero-style-12.hero-banner .banner-thumbnail .thumbnail img {
        border-top-left-radius: 0;
        border-top-right-radius: 370px;
        border-bottom-right-radius: 360px;
        border-bottom-left-radius: 365px;
    }
    .hero-style-15.programming-banner .banner-thumbnail {
        margin-right: inherit;
        margin-top: 40px;
        margin-left: -30px;
        @media #{$md-layout-1} {
           margin-left: 0; 
        }
    }
    .hero-style-15.programming-banner .banner-content .programming-feature-area .feature-course {
        margin-left: 40px;
        margin-right: 0;
    }
    .hero-style-15.programming-banner .banner-content .programming-feature-area .feature-course .content, 
    .hero-style-15.programming-banner .banner-content .programming-feature-area .feature-instructior .content {
        padding-right: 20px;
        padding-left: 0;
    }
    .hero-style-15.programming-banner .banner-content .shape-group li.shape-1 {
        left: inherit;
        right: -109px;
    }
    .hero-style-15.programming-banner .banner-content .shape-group li.shape-2 {
        left: inherit;
        right: -166px;
    }
    .hero-style-15.programming-banner .banner-thumbnail .shape-group li.shape-3 {
        left: inherit;
        right: 120px;
    }
    .hero-style-15.programming-banner .banner-thumbnail .shape-group li.shape-4 {
        right: 31%;
        left: inherit;
    }
    .hero-style-15.programming-banner .banner-thumbnail .shape-group li.shape-5 {
        left: inherit;
        right: 109px;
    }
    .hero-style-15.programming-banner .banner-thumbnail .shape-group li.shape-6 {
        right: inherit;;
        left: -130px;
    }
    .hero-style-15.programming-banner .banner-thumbnail .shape-group li.shape-7 {
        right: inherit;
        left: -164px;
    }
    .hero-style-15.programming-banner .banner-thumbnail .shape-group li.shape-8 {
        right: inherit;
        left: -108px;
    }
    .hero-style-15 .shape-9 {
        right: inherit;
        left: 0;
    }
    .about-style-4.programming-about .about-image-gallery {
        @media #{$sm-layout} {
            margin-left: 0 !important;
        }
    }
    .about-style-4.programming-about .about-image-gallery .shape-group .shape-1 {
        left: inherit !important;
        right: -119px !important;
    }
    .about-style-4.programming-about .about-image-gallery .shape-group .shape-2 {
        right: -58px !important;
        left: inherit !important;
    }
    .about-style-4.programming-about .about-image-gallery .shape-group .shape-3 {
        right: inherit !important;
        left: -23px !important;
    }
    .about-style-4.programming-about .about-image-gallery .shape-group .shape-4 {
        right: inherit !important;
        left: 115px !important;
    }
    .edu-course-area .course-style-17 .content {
        padding: 147px 30px 16px 30px;
    }
    .faq-style-9 .nav-pills .nav-link {
        padding-right: 30px;
        text-align: right;
    }
    .faq-style-9 .edu-faq-content .shape-group li.shape-1 {
        right: -71px;
        left: inherit;
    }
    .faq-style-9 .edu-faq-content .shape-group li.shape-2 {
        right: inherit;
        left: -180px;
    }
    .categorie-area-6 .shape-group li.shape-1 {
        right: inherit;
        left: 0;
    }
    .categorie-area-6 .shape-group li.shape-2 {
        right: inherit;
        left: 160px;
    }
    .categorie-area-6 .shape-group li.shape-3 {
        right: inherit;
        left: 303px;
    }
    .categorie-area-6 .categorie-grid {
        text-align: right;
    }
    .categorie-area-6 .categorie-grid .content {
        margin-left: inherit;
        margin-right: 20px;
    }
    .categorie-area-6 .categorie-grid .content .title {
        text-align: right;
    }
    .programming-wrapper .section-title {
        @media #{$sm-layout} {
            text-align: center !important;
        }
    }
    .course-area-12 .business-course-activation {
        margin: 0 -15px 0 -33%;
    }
    .edu-course.course-style-15 .inner .course-price {
        right: inherit;
        left: 20px;
    }
    .edu-course.course-style-15 .inner .course-meta li {
        margin-right: inherit;
        margin-left: 10px;
    }
    .course-area-12 ul .slider-nav-wrapper {
        text-align: left;
        margin-top: -140px;
        margin-bottom: 55px;
    }
    .business-testimonial .shape-group li.shape-1 {
        right: 0 !important;
    }
    .photography-banner .thumbnail-bg-content .banner-content {
        left: inherit;
        right: 0;
        @media #{$smlg-device} {
            max-width: 100%;
            width: 100%;
            right: inherit;
        }
    }
    .about-style-3 .shape-group li.shape-5 {
        left: inherit;
        right: 110px;
    }
    .about-style-3 .shape-group li.shape-6 {
        left: inherit;
        right: 80px;
    }
    .about-counterup .about-course .about-course-style-2 {
        padding: 0 30px;
        text-align: right;
        padding-right: 30px;
    }
    .about-counterup .about-course .about-course-style-2 .content .title {
        margin-right: 40px;
    }
    .about-counterup .about-course .about-course-style-2.remote-course {
        margin-right: 50px;
    }
    .about-counterup::after {
        left: 638px;
    }
    .photography-about-style .shape-group li.shape-1 {
        right: inherit;
        left: -263px;
    }
    .photography-about-style .shape-group li.shape-2 {
        right: inherit;
        left: -260px;
    }
    .photography-about-style .shape-group li.shape-4 {
        left: inherit;
        right: -245px;
        @media (max-width:1650px) {
            right: 0;
        }
    }
    .photography-about-style .shape-group li.shape-5 {
        left: inherit;
        right: -65px;
        @media (max-width:1650px) {
            right: 170px;
        }
    }
    .photography-about-style .shape-group li.shape-6 {
        right: inherit;
        left: -169px;
        @media (max-width:1650px) {
            left: 0;
        }
    }
    .photography-about-style .shape-group li.shape-7 {
        left: inherit;
        right: 98px;
    }
    .photography-about-style .shape-group li.shape-8 {
        left: inherit;
        right: -98px;
    }
    .photography-about-style .shape-group li.shape-9 {
        left: inherit;
        right: 257px;
    }
    .home-photography-course {
        .section-title {
            display: inline-block;
            margin-top: 0px;
            @media #{$md-layout-1} {
                display: block;
                text-align: center !important;
            }
        }
    }
    .home-photography-course ul .course-view-all {
        text-align: left;
        @media #{$md-layout-1} {
            margin-top: -15px;
            margin-bottom: 33px;
            text-align: center;
        }
    }
    .edu-course-area .course-style-11 .content {
        padding: 30px 0 0 0;
    }
    .edu-course-area .course-style-11 .course-price {
        left: inherit;
        right: 20px;
    }
    .edu-course-area.course-area-11 .course-price {
        left: inherit;
        right: 20px;
    }
    .edu-course-area.course-area-11 .course-style-5 .inner .thumbnail .course-meta {
        left: inherit;
        right: 0;
        padding-left: inherit;
        padding-right: 10px;
    }
    .edu-course-area .course-style-11 .inner .thumbnail .course-meta {
        left: inherit;
        right: 0;
        padding-left: inherit;
        padding-right: 10px;
    }
    .testimonial-area-12 .testimonial-heading-area {
        padding-left: 0;
    }
    .testimonial-area-12 .testimonial-grid {
        padding: 0 0 40px 0px !important;
    }
    .faq-style-7 .edu-faq-gallery .thumbnail-1 {
        margin-right: -277px;
        margin-left: inherit;
    }
    .faq-style-7 .edu-faq-gallery .shape-group li.shape-1 {
        left: inherit;
        right: -37px;
    }
    
    .faq-style-7 .edu-faq-gallery .thumbnail-1 {
        @media only screen and (min-width: 1401px) and (max-width: 1750px) {
            margin-right: -140px;
            margin-left: inherit;
        }
        @media only screen and (min-width: 1200px) and (max-width: 1400px) {
            margin-left: -60px;
            margin-right: 10px;
        }
        @media only screen and (max-width: 1199px) {
            margin-right: -70px;
        }
        @media only screen and (max-width: 991px) {
            margin-right: 0;
        }
    }
    .edu-course-area.course-area-11 .content {
        padding: 20px 0 0 40px;
    }
    .language-about .about-content {
        padding-left: 0;
        padding-right: 40px;
        @media #{$sm-layout} {
            padding-right: 0;
        }
    }
    .language-about .about-image-gallery {
        padding: 80px 0px 20px 30px !important;
        @media #{$md-layout-1} {
            text-align: center !important;
        }
        @media #{$large-mobile} {
            padding: 80px 0 20px 0 !important;
        }

    }
    .language-about .about-image-gallery .main-img-wrapper .main-img-inner {
        left: 32px;
        right: inherit;
        @media #{$lg-layout} {
            left: -72px;
        }
        @media #{$md-layout-1} {
            left: -80px;
        }
    }
    .language-about .about-image-gallery .main-img-wrapper .main-img-inner::after {
        right: inherit;
        left: 20px;
        @media #{$md-layout-1} {
           left: 134px; 
        }
        @media #{$sm-layout} {
            left: 20px;
        }
    }
    .faq-style-6 .edu-faq-gallery {
        bottom: -115px;
        @media #{$smlg-device} {
            left: 0px; 
        }
        @media #{$md-layout-1} {
            bottom: 0px; 
        }
    }
    .faq-style-6 .edu-faq-gallery .thumbnail-1 {
        text-align: left;
        @media #{$md-layout-1} {
            text-align: center;
        }
        img {
            border-radius: 0 !important;
        }
    }
    .faq-style-6 .edu-faq-gallery .shape-group li.shape-1 {
        top: -127px !important;
        left: inherit !important;
        right: 0 !important;
    }
    .faq-style-6 .edu-faq-gallery .shape-group li.shape-2 {
        top: -74px !important;
        left: inherit !important;
        right: 52px !important;
    }
    .faq-style-6 .edu-faq-gallery .shape-group li.shape-3 {
        left: inherit !important;
        right: 367px !important;
    }
    .faq-style-6 .edu-faq-gallery .shape-group li.shape-4 {
        right: inherit !important;
        left: -38px !important;
    }
    .faq-style-6 .edu-faq-gallery .shape-group li.shape-5 {
        left: inherit !important;
        right: -20px !important;
    }
    .edu-course-area.course-area-10 .course-price {
        left: inherit;
        right: 20px;
    }
    .edu-course-area.course-area-10 .flag-wrap {
        right: inherit;
        left: 30px;
    }
    .categorie-area-5 .categorie-grid {
        text-align: right;
    }
    .categorie-area-5 .categorie-grid .content {
        margin-left: inherit;
        margin-right: 20px;
    }
    .edu-course-area.course-area-8 ul .course-view-all,
    .edu-course-area.course-area-9 ul .course-view-all {
        text-align: left;
    }
    .edu-course-area.course-area-9 .edu-course.course-style-5 .inner .thumbnail .course-price {
        left: inherit;
        right: 20px;
    }
    .edu-course-area.course-area-9 .edu-course.course-style-5 .inner .thumbnail .read-more-btn {
        right: inherit;
        left: 30px;
    }
    .edu-course-area.course-area-9 .edu-course.course-style-5 .inner .instructor .instructor-name {
        margin-left: inherit;
        margin-right: 15px;
    }
    .edu-course-area.course-area-9 .edu-course.course-style-5 .inner .content {
        padding: 0px 0px 10px 50px;
    }
    .testimonial-area-9 .testimonial-heading-area {
        padding-left: 0;
    }
    .testimonial-area-9 .home-health-testimonial-activator .swiper-navigation {
        left: inherit; 
        right: 34px;
        @media #{$md-layout-1} {
            right: 0;
        }
    }
    .testimonial-area-9 .shape-group li.shape-1 {
        left: inherit;
        right: -169px;
    }
    .testimonial-area-9 .shape-group li.shape-2 {
        right: inherit;
        left: -150px;
    }
    .testimonial-area-9 .bg-thumbnail .shape-group li.shape-3 {
        left: inherit;
        right: -41px;
    }
    .testimonial-area-9 .bg-thumbnail .shape-group li.shape-4 {
        right: inherit;
        left: -105px;
    }
    .testimonial-area-9 .bg-thumbnail .shape-group li.shape-5 {
        left: inherit;
        right: -54px;
    }
    .photography-testimonial .testimonial-heading-area {
        padding-left: 0;
    }
    .testimonial-area-12 .testimonial-grid {
        padding: 0 0 40px 50px;
    }
    .home-motivation-testimonial-activator {
        margin-right: -15px;
    }
    .testimonial-area-12 .testimonial-grid .content .author-info .thumb {
        margin-right: inherit;
        margin-left: 20px;
    }
    .testimonial-area-12 .testimonial-grid .content .author-info {
        @media #{$md-layout-1} {
            justify-content: center !important;
        }
    }
    .testimonial-area-12 .bg-thumbnail {
        margin-right: 100px;
        margin-left: -200px;
        @media #{$md-layout-1} {
            margin-left: 0;
            margin-right: 0;
        }
    }
    .testimonial-area-12 .shape-group li.shape-1 {
        left: inherit;
        right: -259px;
    }
    .testimonial-area-12 .shape-group li.shape-2 {
        left: inherit;
        right: -495px;
    }
    .testimonial-area-12 .shape-group li.shape-3 {
        right: inherit;
        left: -236px;
    }
    .testimonial-area-12 .shape-group li.shape-4 {
        right: inherit;
        left: -239px;
    }
    .testimonial-area-12 .shape-group li.shape-5 {
        right: inherit;
        left: -289px;
    }
    .testimonial-area-12 .testimonial-grid .content .author-info {
        justify-content: right;
    }
    .programming-wrapper .thumbnail {
        margin-left: 0;
        margin-right: 40px;
    }
    .programming-wrapper .shape-group li.shape-01 {
        left: inherit;
        right: -160px;
    }
    .programming-wrapper .shape-group li.shape-02 {
        left: inherit;
        right: -30px;
    }
    .programming-wrapper .shape-group li.shape-03 {
        left: inherit;
        right: 29%;
    }
    .programming-wrapper .shape-group li.shape-04 {
        left: inherit;
        right: 35%;
    }
    .programming-wrapper .shape-group li.shape-05 {
        right: inherit;
        left: -125px;
    }
    .programming-wrapper .shape-group li.shape-06 {
        right: inherit;
        left: -28px;
    }
    .counterup-area-11 .counterup-style-6 .counterup-wrapper {
        margin-right: 20px;
        text-align: right;
    }
    .counterup-area-11 .counterup-style-6 .counterup-wrapper .count-number {
        text-align: right;
    }
    .event-area-4 .event-view-all-btn a.btn-transparent i {
        padding-right: 6px;
        top: 0px;
        font-size: 20px;
    }
    .health-coach-wrapper.cta-banner-7 .input-group input {
        margin-left: 20px;
        margin-right: inherit;
    }
    .hero-style-16.art-banner .banner-thumbnail .thumbnail img {
        border-radius: 250px;
        border-bottom-right-radius: 0;
    }
    .hero-style-16.art-banner .banner-content .shape-group li.shape-1 {
        left: inherit;
        right: 195px;
    }
    .hero-style-16.art-banner .banner-thumbnail .shape-group li.shape-3 {
        top: -10px;
        left: -164px;
        right: inherit;
        z-index: -1;
    }
    .hero-style-16.art-banner .banner-thumbnail .shape-group li.shape-4 {
        right: -140px;
        left: inherit;
        z-index: -1;
    }
    .hero-style-16.art-banner .banner-thumbnail .shape-group li.shape-5 {
        top: 235px;
        left: inherit;
        bottom: inherit;
        right: 17px;
    }
    .hero-style-16.art-banner .banner-thumbnail .shape-group li.shape-6 {
        right: inherit;
        left: 0;
    }
    .hero-style-16.art-banner .banner-content .shape-group li.shape-2 {
        left: inherit;
        right: -265px;
    }
    .about-style-3.about-style-16 .about-image-gallery .main-img-1 {
        border-radius: 0;
        border-top-left-radius: 200px;
        border-top-right-radius: 200px;
        border-bottom-left-radius: 50px;
    }
    .about-style-4.online-art-about .about-image-gallery-2 .main-img-5 {
        text-align: left;
        margin-top: 133px;
        margin-left: 17px;
    }
    .about-style-4.online-art-about .about-image-gallery-1 .main-img-3 img {
        border-radius: 225px;
        border-bottom-right-radius: 0;
    }
    .about-style-4.online-art-about .about-image-gallery-2 .main-img-4 {
        margin-top: 12px;
        margin-left: 20px;
        margin-right: -66px;
    }
    .about-style-4.online-art-about .about-image-gallery-1 .shape-group li.shape-1 {
        right: inherit;
        left: 57px;
    }
    .about-style-4.online-art-about .about-image-gallery-1 .shape-group li.shape-2 {
        right: inherit;
        left: 66px;
    }
    .about-style-4.online-art-about .about-image-gallery-2 .shape-group li.shape-3 {
        right: inherit;
        left: -206px;
    }
    .about-style-4.online-art-about .about-image-gallery-2 .shape-group li.shape-4 {
        right: inherit;
        left: -85px;
    }
    .edu-course-area .course-style-18 .content {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 100px;
    }
    .edu-course-area .course-style-18 .thumbnail {
        padding: 0 0 0 40px;
    }
    .edu-course-area .course-style-18 .thumbnail a {
        border-top-right-radius: 70px !important;
        border-top-left-radius: 5px !important;
        border-bottom-right-radius: 0 !important;
    }
    .edu-course-area .course-style-18 .thumbnail img {
        border-top-right-radius: 70px;
        border-top-left-radius: 5px;
        border-bottom-right-radius: 0;
    }
    .faq-style-10 .edu-faq-gallery .shape-group li.shape-1 {
        left: -15px;
        right: inherit;
    }
    .faq-style-10 .edu-faq-gallery .shape-group li.shape-2 {
        left: 4px;
        right: inherit;
    }
    .faq-style-10 .edu-faq-content .shape-group li.shape-3 {
        left: -222px;
        right: inherit;
    }
    .faq-style-10 .edu-faq-content .shape-group li.shape-4 {
        left: -303px;
        right: inherit;
    }
    .testimonial-area-14 .shape-group li.shape-1 {
        top: 157px;
        left: inherit;
        right: -70px;
    }
    .testimonial-area-14 .shape-group li.shape-2 {
        right: -93px;
        left: inherit;
    }
    .online-art-wrapper .thumbnail {
        margin-left: 50px;
        text-align: left;
    }
    .online-art-wrapper .shape-group li.shape-01 {
        left: inherit;
        right: -231px;
    }
    .online-art-wrapper .shape-group li.shape-02 {
        left: inherit;
        right: -163px;
    }
    .online-art-wrapper .shape-group li.shape-03 {
        right: inherit;
        left: 24%;
    }
    .online-art-wrapper .shape-group li.shape-04 {
        right: inherit;
        left: 30%;
    }
    .online-art-wrapper .shape-group li.shape-05 {
        right: inherit;
        bottom: 10px;
        left: -125px;
    }
    .online-art-wrapper .shape-group li.shape-06 {
        right: inherit;
        left: -165px;
    }
    .about-style-17 .about-image-gallery .shape-group li.shape-1 {
        left: inherit;
        right: 72px;
    }
    .hero-style-17.digital-marketing-banner .banner-content .instructor-info {
        right: inherit;
        left: 10px;
    }
    .hero-style-17.digital-marketing-banner .banner-content .instructor-info .inner::before {
        left: 0px;
        right: -20px;
    }
    .hero-style-17.digital-marketing-banner .banner-content .title.title-2 {
        margin-left: inherit;
        margin-right: 240px;
    }
    .hero-style-17.digital-marketing-banner .banner-content .shape-group li.shape-1 {
        left: inherit;
        right: -295px;
    }
    .hero-style-17.digital-marketing-banner .banner-content .shape-group li.shape-2 {
        left: inherit;
        right: -135px;
    }
    .hero-style-17.digital-marketing-banner .banner-content .shape-group li.shape-3 {
        left: inherit;
        right: -192px;
    }
    .hero-style-17.digital-marketing-banner .banner-content .shape-group li.shape-4 {
        left: inherit;
        right: 7px;
    }
    .hero-style-17.digital-marketing-banner .banner-content .shape-group li.shape-5 {
        right: inherit;
        left: -312px;
    }
    .hero-style-17.digital-marketing-banner .banner-content .shape-group li.shape-6 {
        right: inherit;
        left: -144px;
    }
    .hero-style-17.digital-marketing-banner .banner-content .shape-group li.shape-7 {
        right: inherit;
        left: -201px;
    }
    .hero-style-17 .shape-8 {
        right: inherit;
        left: 0;
    }
    .hero-style-17 .shape-9 {
        left: inherit;
        right: -68px;
    }
    .hero-style-17 .shape-10 {
        right: inherit;
        left: -70px;
    }
    .features-area-9 .features-wrapper .feature-wrap {
        animation-name: rtl-scroll;
    }
    .about-style-3.about-style-16 .about-image-gallery {
        text-align: left;
    }
    .about-style-3.about-style-16 .about-image-gallery .main-img-inner {
        left: inherit;
        right: -166px;
    }
    .about-style-3.about-style-16 .about-image-gallery .main-img-inner .main-img-2 {
        border-top-left-radius: 120px;
        border-top-right-radius: 120px;
        border-bottom-right-radius: 50px;
        border-bottom-left-radius: 0;
        left: inherit;
        right: 30px;
    }
    .about-style-3.about-style-16 .about-image-gallery .main-img-inner::after {
        border-top-left-radius: 120px;
        border-top-right-radius: 120px;
        border-bottom-right-radius: 50px;
        border-bottom-left-radius: 0;
        left: inherit;
        right: 49px;
    }
    .digital-marketing-blog .read-more-btn .btn-icon-squre {
        font-size: 20px;
    }

    .edu-course-area .course-style-22 .thumbnail .course-price {
        left: 10px;
        right: inherit;
    }
    .hero-style-18 .banner-thumbnail .sales-info .inner .media .single-progressbar .percentCount {
        float: left;
        margin-left: -60px;
    }
    .hero-style-18 .banner-thumbnail .shape-group li.shape-2 {
        left: inherit;
        right: 132px;
    }
    .hero-style-18 .banner-thumbnail .shape-group li.shape-3 {
        left: inherit;
        right: 132px;
    }
    .hero-style-18 .banner-thumbnail .instructor-info .inner .title-wrap {
        justify-content: space-between;
    }
    .hero-style-18 .banner-thumbnail .instructor-info .inner .title-wrap .dot {
        margin-left: 0;
    }
    .hero-style-18 .banner-thumbnail .instructor-info .inner .title {
        margin-right: 0;
    }
    .hero-style-18 .banner-content p {
        max-width: 480px;
    }
    .hero-style-18 .banner-content .shape-group li.shape-1 {
        left: inherit;
        top: -438px;
        right: -477px;
    }
    .process-area-1 .process-gallery .shape-group li.shape-2 {
        right: inherit;
        left: -6px;
    }
    .edu-course-area .course-style-19 .content .course-price {
        right: inherit;
        left: 30px;
        border-radius: 0;
        border-top-left-radius: 35px;
        border-top-right-radius: 35px;
        border-bottom-left-radius: 30px;
    }
    .benefits-area-1 .benefits-wrapper .benefits-content-box .process-content .shape-group li.shape-1 {
        left: inherit;
        right: -447px;
    }
    .benefits-area-1 .benefits-wrapper .benefits-content-box .process-content .shape-group li.shape-2 {
        left: inherit;
        right: -286px;
    }
    .benefits-area-1 .benefits-wrapper .benefits-content-box .process-content .shape-group li.shape-3 {
        right: inherit;
        left: -88px;
    }
    .benefits-area-1 .benefits-wrapper .benefits-content-box .process-content .features-wrapper .features-list li::before {
        top: -6px;
    }
    .edu-team-grid.team-style-9 .inner {
        padding: 0 50px 0 0;
    }
    .edu-team-grid.team-style-9 .inner .thumbnail-wrap {
        width: calc(100% - 30px);
    }
    .why-choose-area-5 .why-choose-gallery .thumbnail.thumbnail-1 img {
        border-radius: 0;
        border-top-left-radius: 230px;
        border-top-right-radius: 230px;
        border-bottom-right-radius: 200px;
    }
    .why-choose-area-5 .why-choose-gallery .thumbnail.thumbnail-1 {
        text-align: right;
    }
    .why-choose-area-5 .why-choose-gallery .video-lesson {
        justify-content: left;
    }
    .why-choose-area-5 .why-choose-gallery .video-lesson .inner::after {
        left: inherit;
        right: -20px;
    }
    .why-choose-area-5 .why-choose-gallery .shape-group li.shape-1 {
        left: inherit;
        right: 16px;
    }
    .why-choose-area-5 .why-choose-gallery .shape-group li.shape-3 {
        left: inherit;
        right: -19px;
    }
    .counterup-area-6 .counterup-style-6:after {
        right: inherit;
        left: -15px;
    }
    .blog-area-11 .blog-style-11 .inner .thumbnail .blog-category {
        left: inherit;
        right: 30px;
    }
    .blog-area-11 .blog-style-11 .inner .content {
        padding: 0;
    }
    .course-area-18 .course-style-21 .content {
        padding: 55px 30px 50px 30px;
    }
    .course-area-18 .course-style-21:hover .content .content-wrap .read-more-btn {
        margin-bottom: -30px;
    }
    .faq-style-11 .edu-faq-gallery .faq-thumbnail.thumbnail-1 {
        text-align: left;
    }
    .ditigtal-marketing-wrapper .btn-group {
        justify-content: left;
    }
    .ditigtal-marketing-wrapper .btn-group .btn-border-curved {
        margin-right: 20px;
        margin-left: 0;
    }
    .ditigtal-marketing-wrapper .shape-group li.shape-01 {
        left: inherit;
        right: -67px;
    }
    .ditigtal-marketing-wrapper .shape-group li.shape-02 {
        left: inherit;
        right: -250px;
    }
    .ditigtal-marketing-wrapper .shape-group li.shape-03 {
        right: inherit;
        left: -248px;
    }
    .ditigtal-marketing-wrapper .shape-group li.shape-04 {
        right: inherit;
        left: -188px;
    }
    .faq-style-11 .edu-faq-gallery .faq-thumbnail.thumbnail-1 img {
        border-radius: 0;
        border-top-left-radius: 230px;
        border-top-right-radius: 230px;
        border-bottom-left-radius: 200px;
    }
    .faq-style-11 .edu-faq-gallery .support-question .inner {
        border-top-left-radius: 23px;
        border-top-right-radius: 65px;
        border-bottom-right-radius: 65px;
        border-bottom-left-radius: 107px;
    }
    .faq-style-11 .edu-faq-gallery .support-question .inner::after {
        border-top-left-radius: 23px;
        border-top-right-radius: 65px;
        border-bottom-right-radius: 65px;
        border-bottom-left-radius: 107px;
        right: inherit;
        left: -20px;
    }
    .faq-style-11 .edu-faq-gallery .shape-group li.shape-1 {
        left: inherit;
        right: 48px;
    }
    .faq-style-11 .edu-faq-gallery .shape-group li.shape-2 {
        left: inherit;
        right: 29px;
    }
    .brand-area-12 .brand-grid-wrap.brand-style-12 .brand-grid:first-child {
        margin-right: -60px;
        margin-left: inherit;
        @media #{$md-layout-1} {
            margin-right: 0;
            margin-top: 18px;
        }
        @media #{$small-mobile} {
            margin-top: 0;
        }
    }
    .brand-area-12 .brand-grid-wrap.brand-style-12 .brand-grid:nth-child(3) {
        margin-right: inherit;
        margin-left: -40px;
        @media #{$md-layout-1} {
            margin-left: 0;
        }
    }
    .brand-area-12 .brand-grid-wrap.brand-style-12 .brand-grid:nth-child(4) {
        margin-right: inherit;
        margin-left: -85px;
        border-left: none;
        @media #{$md-layout-1} {
            margin-left: 0;
        }
    }
    .brand-area-12 .brand-grid-wrap.brand-style-12 .brand-grid:nth-child(5) {
        margin-right: inherit;
        margin-left: -120px;
        @media #{$md-layout-1} {
            margin-left: 0;
        }
    }
    .about-style-17 .about-image-gallery .shape-group li.shape-2 {
        right: 35px;
        left: inherit;
    }
    .about-style-17 .about-image-gallery .shape-group li.shape-3 {
        right: inherit;
        left: -55px;
    }
    .process-area-1 .process-gallery .gallery-thumbnail .thumbnail.thumbnail-1 {
        text-align: left;
    }
    .process-area-1 .process-gallery .shape-group li.shape-1 {
        right: inherit;
        left: -47px;
    }
    .sales-coach-blog .blog-style-2.blog-style-10.first-large-blog .inner .thumbnail .content {
        left: inherit;
        right: 0;
    }
    .sales-coach-blog .blog-style-2.blog-style-10 .inner .thumbnail {
        margin-left: 0;
    }
    .hero-style-19 .banner-thumbnail {
        margin-right: inherit;
        margin-left: -367px;
        margin-top: -100px;
    }
    .hero-style-19 .banner-thumbnail .video-box {
        left: inherit;
        right: 25px;
    }
    .hero-style-19 .shape-group li.shape-1 {
        left: inherit;
        right: -100px;
    }
    .hero-style-19 .banner-content .shape-group li.shape-2 {
        right: inherit;
        left: -230px;
    }
    .hero-style-19 .banner-content .shape-group li.shape-3 {
        right: inherit;
        left: -117px;
    }
    .hero-style-19 .banner-content .shape-group li.shape-4 {
        left: inherit;
        right: -237px;
    }
    .hero-style-19 .banner-content .shape-group li.shape-5 {
        left: inherit;
        right: -143px;
    }
    .hero-style-19 .banner-content .shape-group li.shape-6 {
        right: inherit;
        left: 76px;
    }
    .hero-style-19 .social-wrapper {
        left: inherit;
        right: -35px;
    }
    .hero-style-19 .social-wrapper .social-wrap::after {
        right: inherit;
        left: -2px;
    }
    .quran-learning-banner {
        margin-bottom: 60px;
    }
    .hero-style-19 .banner-content {
        width: 480px;
    }
    .header-style-7.quran-learning .header-mainmenu .header-mainnav .mainmenu-nav .mainmenu {
        margin-left: inherit;
        margin-right: 85px;
    }
    .quran-learning-about .about-image-gallery .main-img-2 {
        right: inherit;
        left: 40px;
    }
    .faq-style-12 .edu-faq-gallery .faq-thumbnail.thumbnail-1 {
        text-align: left;
    }
    .faq-style-12 .edu-faq-gallery .shape-group li.shape-1 {
        left: inherit;
        right: -10px;
    }
    .faq-style-12 .edu-faq-gallery .shape-group li.shape-2 {
        left: inherit;
        right: 39px;
    }
    .edu-team-grid.team-style-8 .team-share-info {
        right: 0 !important;
        left: 0 !important;
    }
    .expert-area-1 .expert-progressbar .single-progressbar .percentCount {
        float: left;
    }
    .hero-style-20 .banner-content .shape-group li.shape-1 {
        left: inherit;
        right: -134px;
    }
    .hero-style-20 .banner-content .shape-group li.shape-2 {
        left: inherit;
        right: -154px;
    }
    .hero-style-20 .banner-thumbnail .shape-group li.shape-3 {
        left: inherit;
        right: 195px;
    }
    .hero-style-20 .banner-thumbnail .shape-group li.shape-4 {
        right: inherit;
        left: 62px;
    }
    .hero-style-20 .banner-thumbnail .shape-group li.shape-5 {
        right: inherit;
        left: 91px;
    }
    .hero-style-20 .banner-thumbnail .shape-group li.shape-6 {
        right: 135px;
        left: inherit;
    }
    .gym-tranning-about .about-image-gallery .main-img-2 {
        right: inherit;
        left: 35px;
    }
    .gym-tranning-about .about-image-gallery .shape-group li.shape-1 {
        left: inherit;
        right: -116px;
    }
    .gym-tranning-about .about-image-gallery .shape-group li.shape-2 {
        left: inherit;
        right: -59px;
    }
    .gym-tranning-about .about-image-gallery .shape-group li.shape-3 {
        right: inherit !important;
        left: -12px !important;
    }
    .benefits-area-1 .shape-group li.shape-1 {
        left: inherit;
        right: -75px;
    }
    .benefits-area-1 .shape-group li.shape-2 {
        left: inherit;
        right: 104px;
    }
    .benefits-area-1 .process-content .shape-group li.shape-3 {
        right: inherit;
        left: -82px;
    }
    .process-content {
        position: relative;

        .section-title {
            p {
                max-width: 400px;
                margin-bottom: 0;
                @media (max-width:1500px) {
                    max-width: 100%;
                }
                @media #{$md-layout-1} {
                   padding-left: 30px;
                }
            }
        }
    }
    .testimonial-area-19 .gym-testimonial-activator .swiper-navigation {
        right: inherit;
        left: 113px;
    }
    .testimonial-area-19 .shape-group li.shape-1 {
        left: inherit;
        right: -52px;
    }
    .testimonial-area-19 .shape-group li.shape-2 {
        left: inherit;
        right: 0;
    }
    .testimonial-area-19 .shape-group li.shape-3 {
        right: inherit;
        left: -384px;
    }
    .testimonial-area-19 .shape-group li.shape-4 {
        right: inherit;
        left: -239px;
    }
    .breadcrumb-style-6 .edu-breadcrumb,
    .breadcrumb-style-7 .edu-breadcrumb,
    .breadcrumb-style-8 .edu-breadcrumb,
    .breadcrumb-style-6 .course-meta,
    .breadcrumb-style-7 .course-meta,
    .breadcrumb-style-8 .course-meta {
        justify-content: right;
    }
    .breadcrumb-style-6 .breadcrumb-inner,
    .breadcrumb-style-7 .breadcrumb-inner,
    .breadcrumb-style-8 .breadcrumb-inner {
        text-align: right;
    }
    .course-details-4 .nav-tabs,
    .course-details-7 .nav-tabs {
        justify-content: right;
    }
    .pricing-area-1 .gym-nav-wrapper .year-wrap {
        left: inherit;
        right: 13px;
    }
    .pricing-area-1 .gym-nav-wrapper .month-wrap {
        left: -328px;
        right: inherit;
    }
    .benefits-area-1 .process-content .features-wrapper .features-list li {
        padding-right: 25px;
    }
    .benefits-area-1 .process-content .features-wrapper .features-list li::before {
        left: inherit;
        right: 0;
        top: -5px;
    }
    .edu-team-grid.team-style-9 .inner .thumbnail-wrap .content .designation {
        position: relative;
        top: -5px;
    }
    //about 
    .edu-about-area {
        .about-content {
            .features-list {
                li {
                    padding-left: 0;
                    padding-right: 35px;
                    &::before {
                        left: inherit;
                        right: 0;
                    }
                }
            }
        }
        .award-status {
            right: inherit;
            left: 70px;
            .inner {
                .icon {
                    margin-right: 0;
                }
                .content {
                    text-align: right;
                    margin-right: 15px;
                }
            }
        }

        &.about-style-1 {
            
            .about-image-gallery {
                .video-box {
                    right: inherit;
                    left: 50px;
                }
                .shape-group {
                    li {
                        &.shape-1 {
                            left: inherit;
                            right: -100px;
                        }
                        &.shape-2 {
                            left: inherit;
                            right: -50px;
                        }
                        &.shape-3 {
                            right: inherit;
                            left: 120px;
                        }
                    }
                }
            }
            .shape-group {
                li {
                    right: inherit;
                    left: -210px;
                }
            }
        }
        &.about-style-2 {
            .about-image-gallery {
                padding: 80px 70px 60px 0;
                
                .author-box {
                    right: inherit;
                    left: 0px;
                }
                .shape-group {
                    li {
                        &.shape-1 {
                           left: inherit;
                           right: -80px; 
                        }
                        &.shape-2 {
                            left: inherit;
                            right: -55px; 
                        }
                        &.shape-3 {
                            right: inherit;
                            left: -5px; 
                        }
                    }
                }
            }
            .about-content {
                padding-left: inherit;
                padding-right: 80px;
                @media #{$md-layout-1} {
                   padding-right: 0;
                }
            }
            .shape-group {
                li {
                    right: inherit;
                    left: -210px;
                }
            }
            .award-status {
                left: inherit;
                right: -20px;
                @media #{$custom-lg-device-eight} {
                    right: 20px;
                }
                @media #{$md-layout-1} {
                    right: 20px;
                }
            }
        }
        &.about-style-3 {
            .about-content {
                .nav-tabs {
                    .nav-item {
                        &::after {
                            top: 6px;
                            right: inherit;
                            left: -18px;
                        }
                    }
                }
            }
            .about-image-gallery {
                padding: 80px 30px 65px 0;
                text-align: right;

                .main-img-2 {
                    right: inherit;
                    left: 0;
                    @media #{$md-layout} {
                        left: 115px;
                    }
                }
                .shape-group {
                    li {
                        &.shape-1 {
                            right: inherit;
                            left: 95px;
                        }
                        &.shape-2 {
                            right: inherit;
                            left: 39px;
                        }
                        &.shape-3 {
                            right: -3px;
                            left: inherit;
                        }
                        &.shape-4 {
                            right: inherit;
                            left: 40px;
                        }
                    }
                }
            }
            .shape-group {
                li {
                    &.shape-1 {
                        left: inherit;
                        right: 110px;
                    }
                    &.shape-2 {
                        left: inherit;
                        right: 80px;
                    }
                }
            }
        }
        &.about-style-4 {
            .about-image-gallery {
                margin-right: inherit;
                margin-left: 30px;
                .main-img-2 {
                    right: inherit;
                    left: 0;
                }
                .shape-group {
                    .shape-1 {
                        right: inherit;
                        left: 85px;
                    }
                    .shape-2 {
                        right: inherit;
                        left: 31px;
                    }
                    .shape-3 {
                        left: inherit;
                        right: -32px;
                    }
                    .shape-4 {
                        right: inherit;
                        left: 70px;
                    }
                }
            }
        }
        &.about-style-5 {
            .about-image-gallery {
                .shape-group {
                    li {
                        &.shape-1 {
                            right: inherit;
                            left: -10px;
                        }
                        &.shape-2 {
                            left: inherit;
                            right: -80px;
                        }
                    }
                }
            }
            .shape-group {
                li {
                    &.shape-1 {
                        right: inherit;
                        left: 180px;
                    }
                }
            }
            .about-content {
                padding-left: inherit;
                padding-right: 30px;
            }
        }
        &.about-style-6 {
            .about-image-gallery {
                .main-img-2 {
                    text-align: left;
                }
                .shape-group {
                    .shape-1 {
                        right: inherit;
                        left: 34px;
                    }
                    .shape-2 {
                        left: inherit;
                        right: -15px;
                    }
                    .shape-3 {
                        left: inherit;
                        right: 20px;
                    }
                }
            }
        }
        &.about-style-7 {
            .about-image-gallery {
                text-align: left;
                padding: 80px 0 0 50px;

                .main-img-2 {
                    left: -22px;
                    right: inherit;
                }
                .shape-group {
                    li {
                        &.shape-1 {
                            left: inherit;
                            right: 40px;
                        }
                        &.shape-2 {
                            left: inherit;
                            right: 40px;
                        }
                        &.shape-3 {
                            right: inherit;
                            left: -10px;
                        }
                        &.shape-4 {
                            left: inherit;
                            right: 10px;
                        }
                    }
                }
            }
            > .shape-group {
                > li {
                    left: inherit;
                    right: 125px;
                }
            }
        }
        &.about-style-8 {
            &::after {
                left: inherit;
                right: -70px;
            }
            .about-content {
                padding-right: inherit;
                padding-left: 40px;

                .about-mission {
                    .single-item {
                        padding-right: inherit;
                        padding-left: 40px;
                    }
                }
            }
            .about-image-gallery {
                .shape-group {
                    li {
                        &.shape-1 {
                            left: inherit;
                            right: -90px;
                        }
                        &.shape-2 {
                            left: inherit;
                            right: 25px;
                        }
                        &.shape-3 {
                            right: inherit;
                            left: -70px;
                        }
                        &.shape-4 {
                            right: inherit;
                            left: -165px;
                        }
                    }
                }
            }
        }
    }
    .about-style-9 .about-image-gallery .author-box {
        right: inherit;
        left: 38px;
    }
    .about-style-9 .about-content {
        padding-right: 45px;
        @media #{$md-layout-1} {
           padding-right: 0; 
        }
    }
    .about-style-9 .about-image-gallery .shape-group li.shape-1 {
        left: inherit;
        right: -126px;
    }
    .about-style-9 .about-image-gallery .shape-group li.shape-2 {
        left: inherit;
        right: -63px;
    }  
    .about-style-9 .about-image-gallery .shape-group li.shape-3 {
        right: inherit;
        left: -5px;
    }  
    .about-style-9 .shape-group li.shape-4 {
        right: inherit;
        left: -150px;
    }
    //brand 
    .brand-grid-wrap {
        
        .brand-grid {
            &:first-child {
                border-left: none;
            }
            &:nth-child(4n) {
                border-left: 1px solid #f0f0f0;
            }
            &:nth-child(5n) {
                border-left: none;
            }
        }
        &.brand-style-2 {
            .brand-grid {
                &:first-child {
                    border-right: none;
                }
                &:last-child {
                    border-right: 1px solid #f0f0f0;
                }
                &:nth-child(5n) {
                    border-right: none;
                }
            }
        }      
    }
    .brand-section-heading {
        padding-right: inherit;
        padding-left: 60px;
    }

    //Blog
    .edu-blog-area {
        &.blog-area-2 {
            .shape-group {
                li {
                    &.shape-1 {
                        top: 0;
                        left: inherit;
                        right: 135px;
                        @media #{$lg-layout-1} {
                            right: 0;
                        }
                    }
                }
            }
        }
    }
    .edu-blog-area.blog-area-6 .thumbnail .date {
        left: inherit;
        right: 20px;
    }
    .edu-blog-area.blog-area-6 .edu-blog .content.position-top .read-more-btn {
        right: inherit;
        left: 30px;
    }


    .blog-meta {
        li {
            &::after {
                right: inherit;
                left: -18px;
                @media #{$smlg-device} {
                    left: -11px;
                }
            }
            i {
                padding-right: inherit;
                padding-left: 10px;
            }
        }
    }
    .edu-blog {
        &.blog-style-2 {
            &.first-large-blog {
                .content {
                    padding-left: inherit;
                    padding-right: 110px;

                    .blog-date {
                        left: auto;
                        right: 0;
                    }
                }
            }
            .thumbnail {
                margin-right: inherit;
                margin-left: 30px;
                @media #{$large-mobile} {
                    margin-left: 0;
                }
            }
            .blog-date {
                right: inherit;
                left: 0;
                border-radius: 60px 60px 60px 14px;
            }
        }
    }
    .edu-blog-widget {
        &.widget-latest-post {
            .latest-post {
                .thumbnail {
                    margin-right: inherit;
                    margin-left: 20px;
                }
            }
        }
    }
    .edu-blog {
        &.blog-style-list {
            .thumbnail {
                margin-right: inherit;
                margin-left: 40px;
            }
        }
        .content {
            &.position-top {
                .read-more-btn {
                    right: inherit;
                    left: 30px;
                }
            }
        }
    }
    blockquote {
        padding: 35px 120px 35px 40px;
        &::after {
            left: inherit;
            right: 40px;
        }
    }
    .blog-details-content {
        .blog-tags,
        .blog-share {
            .title {
                margin-right: inherit;
                margin-left: 12px;
            }
        }
        ul {
            padding-left: inherit;
            margin-right: 20px;
        }
    }
    .blog-author {
        .thumbnail {
            margin-right: inherit;
            margin-left: 30px;
        }
    }
    .blog-pagination-list {
        a {
            i {
                margin-right: inherit;
                margin-left: 20px;
            }
        }
    }
    //campus
    .edu-campus-area {
        .campus-content {
            margin-left: inherit;
            margin-right: -120px;
            @media #{$smlg-device} {
                margin-right: 0;
            }
            .features-box {
                text-align: right;

                .icon {
                    margin-right: inherit;
                    margin-left: 20px;
                }
            }
            .shape-4 {
                right: inherit;
                left: -158px;
            }
            .shape-5 {
                right: inherit;
                left: -64px;
            }
            .shape-6 {
                right: inherit;
                left: -28px;
            }
        }
        .campus-image-gallery {
            .shape-1 {
                left: inherit;
                right: -235px;
            }
            .shape-2 {
                left: inherit;
                right: -144px;
            }
            .shape-3 {
                left: inherit;
                right: -85px;
            }
        }
    }
    //counterup 
    .counterup-area-2 .counterup-box-wrap .counterup-box .edu-counterup:first-child {
        border-right: none;
        border-left: 0.8px solid var(--color-border);
        @media #{$small-mobile} {
            border-left: none;
        }
    }
    .edu-counterup.counterup-style-3:after {
        right: inherit;
        left: -15px;
    }
    .counterup-area-7 .counterup-box-wrap .counterup-box .edu-counterup:first-child {
        border-right: none;
        border-left: 0.8px solid var(--color-border);
        @media #{$small-mobile} {
            border-left: none;
        }
    }
    .counterup-area-7 {
        .counterup-content {
            padding-right: inherit;
            padding-left: 80px;
        }
        .counterup-box-wrap {
            .shape-group {
                li {
                    &.shape-1 {
                        left: inherit;
                        right: -90px;
                    }
                    &.shape-2 {
                        left: inherit;
                        right: -15px;
                    }
                    &.shape-3 {
                        right: inherit;
                        left: -90px;
                    }
                    &.shape-4 {
                        right: inherit;
                        left: 30px;
                    }
                }
            }
        }
    }
    // Features area
    .features-box {
        &.features-style-2 {
            text-align: right;
            padding: 30px 0;
            border-left: 1px solid rgba(255, 255, 255, 0.15);
            border-right: 0;
            padding-left: inherit;
            padding-right: 40px;

            @media #{$md-layout-1} {
                &:nth-child(2) {
                    border-left: none;
                }
            }

            &:last-child {
                border-left: none;
            }
            .content {
                padding: 0 20px 0 0;
            }
        }
        &.features-style-3 {
            text-align: right;
            &:first-child {
                padding-left: inherit;
                padding-right: 50px;
            }

            .icon {
                margin-right: inherit;
                margin-left: 20px;
            }
        }
        &.features-style-4 {
            text-align: right;

            .icon {
                margin-right: inherit;
                margin-left: 30px;
            }
        }
    }

    .edu-cta-banner-area-6 .thumbnail img {
        margin-left: inherit;
        margin-right: 60px;
    }
    .edu-cta-banner-area-6 .edu-cta-banner .shape-group li.shape-01 {
        left: inherit !important;
        right: -8px !important;
    }
    .edu-cta-banner-area-6 .edu-cta-banner .shape-group li.shape-02 {
        left: inherit !important;
        right: -64px !important;
    }
    .edu-cta-banner-area-6 .edu-cta-banner .shape-group li.shape-03 {
        left: inherit !important;
        right: 28% !important;
    }
    .edu-cta-banner-area-6 .edu-cta-banner .shape-group li.shape-04 {
        right: 29% !important;
        left: inherit !important;
    }
    .edu-cta-banner-area-6 .edu-cta-banner .shape-group li.shape-05 {
        right: inherit !important;
        left: -251px !important;
    }
    .edu-cta-banner-area-6 .edu-cta-banner .shape-group li.shape-06 {
        right: inherit !important;
        left: -32% !important;
    }
    //categories 
    .categorie-grid {
        &.categorie-style-2 {
            text-align: right;
        }
    }
    .categorie-area-4 {
        .categorie-grid {
            text-align: right;
            .icon {
                margin: 0 0 0 20px;
            }
        }
    }

    //Course 
    .edu-course {
        .thumbnail {
            .time-top {
                right: inherit;
                left: 10px;
            }
        }
        .content {
            .course-rating {
                .rating-count {
                    margin-left: 0;
                    margin-right: 10px;
                }
            }
            .course-meta {
                li {
                    margin-right: 0;

                    &:last-child {
                        margin-right: 22px;
                    }

                    &::after {
                        right: inherit;
                        left: -25px;
                    }
                    i {
                        margin-right: inherit;
                        margin-left: 7px;
                    }
                }
            }
        }
        .course-hover-content {
            .content {
                .wishlist-btn {
                    right: inherit;
                    left: 10px;
                }
            }
        }
        &.course-style-4 {
            .thumbnail {
                margin-right: inherit;
                margin-left: 30px;
                @media #{$large-mobile} {
                    margin-left: 0;
                }

                .time-top {
                    left: inherit;
                    right: 10px;
                }
            }
        }
        &.course-style-5 {
            .content {
                .price-round {
                    right: inherit;
                    left: 30px;
                }
            }
        }
        &.course-style-6 {
            .thumbnail {
                .price-round {
                    right: inherit;
                    left: 20px;
                }
            }
            .content {
                .course-meta {
                    li {
                        &:last-child {
                           margin-right: 25px; 
                        }
                    }
                }
            }
        }
        &.course-style-7 {
            .thumbnail {
                .course-price {
                    right: inherit;
                    left: 30px;
                }
            }
            .content {
                .course-meta {
                    li {
                        &:last-child {
                            margin-right: 20px;
                        }
                    }
                }
            }
        }
    }
    .course-area-8 .edu-course.course-style-4::after {
        background: linear-gradient(-270deg, #31b978 0%, #1ab69d 100%);
    }
    .course-area-8 .course-style-4 .thumbnail .time-top {
        left: inherit !important;
        right: 0;
    }
    .course-area-8 .shape-group li.shape-1 {
        left: inherit;
        right: -280px;
    }
    .course-area-8 .shape-group li.shape-2 {
        left: inherit;
        right: -660px;
    }
    .course-area-8 .shape-group li.shape-3 {
        right: inherit;
        left: -101px;
    }
    .course-area-8 .shape-group li.shape-4 {
        right: inherit;
        left: -100px;
    }
    .course-area-8 ul .section-title {
        @media #{$md-layout-1} {
            text-align: center;
        }
    }
    .edu-cta-banner-area-6 .section-title.section-left {
        @media #{$md-layout-1} {
            text-align: center;
            padding: 70px 110px;
        }
        @media #{$large-mobile} {
            padding: 50px 60px;
        }
    }
    .course-layout-five-tooltip-content {
        .content {
            .course-meta {
                li {
                    margin-right: inherit;
                    margin-left: 25px;
                    &::after {
                        right: inherit;
                        left: -17px;
                    }
                }
            }
            .course-feature {
                ul {
                    li {
                        padding-left: inherit;
                        padding-right: 28px;
                        &::before {
                            left: inherit;
                            right: 0;
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
    .home-four-course {
        .shape-group {
            .shape-1 {
                right: inherit;
                left: -88px;
            }
            .shape-2 {
                right: inherit;
                left: -406px;
            }
        }
    }

    //event 
    .edu-event-area {
        .edu-event {
            .thumbnail {
                .event-time {
                    left: inherit;
                    right: 10px;
                }
            }
            .content {
                .event-date {
                    right: inherit;
                    left: 30px;
                }
                .event-meta {
                    li {
                        i {
                            padding-right: inherit;
                            padding-left: 10px;
                        }
                    }
                }
            }
        }
        .shape-group {
            li {
                &.shape-1 {
                    left: inherit;
                    right: -81px;
                }
                &.shape-2 {
                    left: inherit;
                    right: -190px;
                }
            }
        }
        &.event-area-1 {
            .edu-event-list {
                &.event-list-2 {
                    .content {
                        padding-left: 0;
                        padding-right: 40px;

                        @media #{$sm-layout} {
                           padding-right: 0; 
                        }

                        .event-meta {
                            li {
                                i {
                                    padding-right: inherit;
                                    padding-left: 10px;
                                }
                            }
                        }
                        .event-location {
                            i {
                                padding-right: inherit;
                                padding-left: 10px;
                            }
                        }
                    }
                }
            }
        }
        &.event-area-3 {
            .edu-event-list {
                .content {
                    padding-left: inherit;
                    padding-right: 28px;

                    .event-meta {
                        li {
                            i {
                                padding-right: 0;
                            }
                        }
                    }

                    .event-location {
                        i {
                            padding-right: inherit;
                            padding-left: 10px;
                        }
                    }
                }
            }
        }
    }
    .event-details-area {
        .event-details {
            .details-content {
                ul {
                    padding-left: inherit;
                    padding-right: 20px;
                }
                .event-meta {
                    padding-right: 0;
                    li {
                        &::after {
                           right: inherit;
                           left: -20px; 
                        }
                        i {
                            padding-right: inherit;
                            padding-left: 10px;
                        }
                    }
                }
            }
        }
    }
    .edu-course-widget {
        &.widget-course-summery {
            .content {
                .course-item {
                    li {
                        i {
                            padding-right: inherit;
                            padding-left: 15px;
                        }
                    }
                }
            }
        }
    }
    .countdown {
        .countdown-section {
            margin-right: inherit;
            margin-left: 15px;
        }
    }

    .edu-breadcrumb-area {
        .course-meta {
            li {
                &::after {
                    right: inherit;
                    left: -20px;
                }
                i {
                    padding-right: inherit;
                    padding-left: 14px;
                }
            }
            .course-rating {
                .rating {
                    margin-left: 8px;
                }
            }
        }
        &.breadcrumb-style-3 {
            .breadcrumb-inner {
                .page-title {
                    .title {
                        text-align: right;
                    }
                }
            }
        }
        &.breadcrumb-style-4 {
            .page-title {
                .title {
                    width: 52%;
                }
            }
        }
    }
    .edu-course-widget {
        &.widget-course-summery {
            .content {
                .course-item {
                    li {
                        svg {
                            margin-right: inherit;
                            margin-left: 15px;
                        }
                    }
                }
            }
        }
    }
    .course-overview {
        ul {
            li {
                padding-left: inherit;
                padding-right: 30px;
                &::after {
                    left: inherit;
                    right: 0;
                }
            }
        }
    }
    .course-curriculam {
        .course-lesson {
            ul {
                li {
                    .text {
                        i {
                            margin-right: inherit;
                            margin-left: 8px;
                        }
                    }
                }
            }
        }
    }
    
    .course-instructor {
        .thumbnail {
            margin-right: inherit;
            margin-left: 30px;
        }
    }
    .course-details-content  {
        .entry-content {
            .course-meta {
                li {
                    &::after {
                        right: inherit;
                        left: -20px;
                    }

                    i {
                        padding-right: inherit;
                        padding-left: 14px;
                    }
                }
                .course-rating {
                    .rating {
                        margin-right: inherit;
                        margin-left: 8px;
                    }
                }
            }
        }
        .course-enroll-box {
            .single-item {
                &::after {
                    right: inherit;
                    left: 0;
                    @media #{$sm-layout} {
                        height: 1px;
                        width: 100%;
                        bottom: 0;
                        top: auto;
                        left: 50%;
                        transform: translateX(-50%);                    
                    }
                }
            }
        }
    }
    .course-details-3 {
        .course-curriculam {
            .edu-accordion {
                .accordion-item {
                    .accordion-button {
                        text-align: right;
                        padding: 30px 60px 30px 71px;

                        &::after {
                            right: inherit;
                            left: 47px;
                        }
                        &::before {
                            left: inherit;
                            right: 30px;
                        }
                        .expand {
                            right: inherit;
                            left: 30px;
                        }
                    }
                }
                .course-lesson {
                    .reading-status {
                        padding: 22px 30px 20px 30px;
                        .title {
                            i {
                                margin-right: inherit;
                                margin-left: 15px;
                            }
                        }
                        .complete-text {
                            span {
                                &::after {
                                    right: inherit;
                                    left: -12px;
                                }
                                &:last-child {
                                    margin-right: 20px;
                                }
                            }
                        }
                    }
                    ul {
                        li {
                            padding: 14px 100px 14px 40px;

                            &::before {
                                left: inherit;
                                right: 70px;
                            }
                        }
                    }
                }
            }
        }
    }
    .course-review {
        .comment-form-area {
            .rating-icon {
                .title {
                    margin-right: inherit;
                    margin-left: 22px;
                }
            }
        }
        .border-box {
            .rating-box {
                margin: 30px 30px;
            }
        }
    }

    //Faq

    .faq-accordion {
        .accordion-item {
            .accordion-button {
                &::before {
                    right: inherit;
                    left: 30px;
                }
            }
        }
    }
    .edu-faq-area {
        &.faq-style-1 {
            .edu-faq-content {
                .shape-group {
                    li {
                        &.shape-1 {
                            right: inherit;
                            left: -145px;
                        }
                        &.shape-2 {
                            right: inherit;
                            left: -165px;
                        }
                    }
                }
            }
            .edu-faq-gallery {
                .thumbnail-1,
                .thumbnail-3 {
                    text-align: left;
                }
                .thumbnail-1 {
                    img {
                        border-radius: 120px 120px 120px 0;
                    }
                }
                .thumbnail-4 {
                    img {
                        border-radius: 50px 0 120px 120px;
                    }
                }
                .shape-group {
                    li {
                        &.shape-1 {
                            left: inherit;
                            right: 44%;
                        }
                        &.shape-2 {
                            left: -10px;
                            right: inherit;
                        }
                        &.shape-3 {
                            left: inherit;
                            right: -7px;
                        }
                        &.shape-4 {
                            left: inherit;
                            right: -45px;
                        }
                    }
                }
            }
        }
        &.faq-style-2 {
            .edu-faq-gallery {
                .shape-group {
                    .shape-1 {
                        left: inherit;
                        right: -35px;
                    }
                    .shape-2 {
                        left: inherit;
                        right: 210px;
                    }
                    .shape-3 {
                        right: inherit;
                        left: -22px;
                    }
                    .shape-4 {
                        right: inherit;
                        left: -58px;
                    }
                    .shape-5 {
                        left: inherit;
                        right: -50px;
                    }
                    .shape-6 {
                        left: inherit;
                        right: 90px;
                    }
                }
            }
        }
        &.faq-style-3 {
            .edu-faq-gallery {
                text-align: left;

                .shape-group {
                    .shape-1 {
                        right: inherit;
                        left: -50px;
                    }
                    .shape-2 {
                        right: inherit;
                        left: -97px;
                    }
                    .shape-3 {
                        left: inherit;
                        right: -68px;
                    }
                }
            }
        }
        &.faq-style-4 {
            .faq-thumbnail {
                .shape-group {
                    li {
                        &.shape-1 {
                            right: inherit;
                            left: 20px;
                        }
                        &.shape-2 {
                            right: inherit;
                            left: -55px;
                            top: -44px;
                        }
                        &.shape-3 {
                            left: inherit;
                            right: -5px;
                        }
                    }
                }
            }
        }
    }
    .faq-style-5 .edu-faq-gallery .shape-group li.shape-1 {
        left: inherit;
        right: 3px;
    }
    .faq-style-5 .edu-faq-gallery .shape-group li.shape-2 {
        right: inherit;
        left: 147px;
    }
    .faq-style-5 .edu-faq-gallery .shape-group li.shape-3 {
        left: inherit;
        right: -122%;
        bottom: -10px;
    }
    .faq-style-5 .edu-faq-content .shape-group li.shape-1 {
        right: inherit;
        left: -145px;
    }
    .faq-style-5 .edu-faq-content .shape-group li.shape-2 {
        right: inherit;
        left: -165px;
    }
    .faq-page-nav {
        padding-right: inherit;
        padding-left: 60px;
        @media #{$large-mobile} {
            padding-left: 0;
        }
        .nav-tabs {
            .nav-link {
                padding-right: 30px;
                &::after {
                    top: 4px;
                    right: 0;
                    left: inherit;
                    transform: rotateY(180deg);
                }
            }
        }
    }

    //section 
    .section-title {
        &.section-left {
            text-align: right;
        }
    }

    //testimonial
    .testimonial-grid {
        &::after {
            right: inherit;
            left: -12px;
            transform: rotateY(180deg);
        }
        .thumbnail {
            .qoute-icon {
                right: inherit;
                left: -12px;
                transform: rotateY(180deg);
            }
        }
    }
    .testimonial-heading-area {
        padding-right: 0;
        padding-left: 50px;
    }
    .testimonial-slide {
        .author-info {
            .thumb {
                margin-right: inherit;
                margin-left: 20px;
            }
        }
    }
    .testimonial-area-2 {
        .shape-group {
            .shape-1 {
                right: inherit;
                left: -75px;
            }
            .shape-2 {
                right: inherit;
                left: -106px;
            }
            .shape-3 {
                bottom: inherit;
                top: 300px;
            }
        }
    }
    .testimonial-area-3 .shape-group li.shape-5 {
        bottom: inherit;
        top: 144px;
    }
    .testimonial-area-4 {
        .swiper-navigation {
            text-align: right;

            .swiper-btn-prv,
            .swiper-btn-nxt {
                transform: rotateY(180deg);
            }
        }
        .shape-group {
            li {
                &.shape-1 {
                    left: inherit;
                    right: 42%;
                }
                &.shape-2 {
                    right: inherit;
                    left: 12%;
                }
            }
        }
    }
    .testimonial-area-6 {
        .shape-group {
            li {
                &.shape-1 {
                    left: inherit;
                    right: -100px;
                }
                &.shape-2 {
                    right: inherit;
                    left: -94px;
                }
                &.shape-3 {
                    right: inherit;
                    left: -250px;
                }
            }
        }
        .swiper-pagination {
            text-align: left;
        }
    }
    .testimonial-area-8 {
        .testimonial-slide {
            .author-info {
                .thumb {
                    margin-left: 0;
                }
            }
        }
    }


    //team 
    .edu-team-grid {
        &.team-style-1 {
            .team-share-info {
                right: inherit;
                left: 20px;
            }
        }
        &.team-style-3 {
            .content {
                left: inherit;
                right: 30px;
                text-align: right;
            }
        }
        &.team-style-4 {
            .content {
                text-align: right;
                margin-left: inherit;
                margin-right: 30px;
            }
        }
    }
    .team-area-3 {
        .shape-group {
            li {
                &.shape-1 {
                    left: inherit;
                    right: 135px;
                }
            }
        }
    }
    .team-details-content  {
        .main-info {
            .team-meta {
                li {
                    margin-right: inherit;
                    margin-left: 40px;

                    &::after {
                        right: inherit;
                        left: -22px;
                    }

                    i {
                        margin-right: inherit;
                        margin-left: 7px;
                    }
                }
            }
        }
        .contact-info {
            ul {
                li {
                    span {
                        min-width: 70px;
                    }
                }
            }
        }
    }

    
    //footer 
    .edu-footer {
        .edu-footer-widget {
            &.explore-widget {
                margin-left: 0;
                margin-right: 65px;
                @media #{$md-layout-1} {
                    margin-right: 0;
                }
            }
            .input-group {
                button {
                    margin-left: inherit !important;
                    margin-right: 10px !important;
                }
            }
        }
    }
    .edu-btn {
        .icon-west {
            font-size: 18px;
            font-weight: bold;
            top: 2px;
            right: 4px;
        }
    }
    .btn-icon-round {
        font-size: 18px;
        font-weight: bold;
    }

    // Why Choose Us
    .why-choose-area-1 {
        .features-list {
            .features-box {
                .content {
                    text-align: right;
                }
                .icon {
                    margin-right: inherit;
                    margin-left: 30px;
                }
            }
        }
        .why-choose-gallery {
            .thumbnail {
                &.thumbnail-1 {
                    text-align: left;
                }
            }
            .shape-group {
                li {
                    &.shape-1 {
                        left: inherit;
                        right: 30px;
                    }
                    &.shape-2 {
                        left: 45px;
                        right: inherit;
                    } 
                }
            }
        }

    }
    .why-choose-area-3 {
        .section-title-flex {
            .left-content {
                margin-right: inherit;
                margin-left: 90px;

                &::after {
                    right: inherit;
                    left: -20px;
                }
            }
        }
        .shape-group {
            li {
                &.shape-1 {
                    left: inherit;
                    right: 75px;
                }
                &.shape-2 {
                    left: inherit;
                    right: 120px;
                }
                &.shape-3 {
                    right: inherit;
                    left: -60px;
                }
            }
        }
    }
    //NwewsLatter

    .newsletter-form {
        .input-group {
            .btn-curved {
                margin-left: inherit;
                margin-right: 20px !important;
            }
        }
    }

    // pricing

    .pricing-table {
        .pricing-body {
            .list-item {
                li {
                    i {
                        margin-right: inherit;
                        margin-left: 10px;
                    }
                }
            }
        }
    }

    //privacy
    .privacy-policy {
        @media #{$sm-layout} {
            padding-right: 0;
        }
        ul {
            li {
                padding-left: inherit;
                padding-right: 30px;
                
                &::after {
                    left: inherit;
                    right: 0;
                }
            }
        }
    }

    // Log in form

    .login-form-box {
        .form-group {
            .password-show {
                right: inherit;
                left: 18px;
            }
        }
    }

    //shop 
    .edu-sorting-area {
        .sorting-right {
            .edu-sorting {
                .icon {
                    left: inherit;
                    right: 22px;
                }
                .edu-select {
                    padding-left: inherit;
                    padding-right: 50px;
                }
            }
            .layout-switcher {
                margin-right: inherit;
                margin-left: 30px;
                label {
                    margin-right: inherit;
                    margin-left: 10px;
                }
            }
        }
    }
    select {
        background: url(../../images/icons/arrow-icon.png) 10% center no-repeat;
        background-color: var(--color-lighten01);
    }

    //Products 
    .product-details-area  {
        .content {
            .product-action {
                .add-to-cart-btn {
                    a {
                        margin-right: inherit;
                        margin-left: 10px;
                    }
                }
            }
        }
    }
    .product-description-content {
        .comment-form {
            .review-rating {
                .title {
                    margin-right: inherit;
                    margin-left: 15px;
                }
            }
        }
    }
    .comment-list-wrapper {
        .comment {
            .thumbnail {
                margin-right: inherit;
                margin-left: 30px;
            }
        }
    }
    // contact me
    .contact-me {
        .thumbnail {
            margin-right: inherit;
            margin-left: 130px;
            @media #{$md-layout} {
                margin-left: 50px;
            }
            @media #{$sm-layout} {
                margin-left: 0;
            }
        }
    }

    //Billing
    .checkout-billing {
        .form-group {
            select {
                background: url(../../images/icons/arrow-icon.png) 11% center no-repeat transparent;
            }
        }
    }
    // Back to Top
    .rn-progress-parent {
        right: inherit;
        left: 30px;
    }
    .isotop-button {
        &.button-transparent {
            button {
                &::after {
                    bottom: -3px;
                }
            }
        }
    }

    input[type="checkbox"] ~ label,
    input[type="radio"] ~ label {
        padding-left: inherit;
        padding-right: 40px;

        &::before {
            left: inherit;
            right: 0;
        }
        &::after {
            left: inherit;
            right: 3px;
        }
    }
    .input-group  {
        .form-control {
            text-align: right;
        }
    }
    .form-group {
        input,
        textarea {
            text-align: right;
        }
    }

    .preloader-close-btn-wraper {
        right: inherit;
        left: 0;
    }

    //Swiper Slider
    .swiper-testimonial-slider-wrapper {
        .swiper-slide {
            &.swiper-slide-visible {
                &.swiper-slide-prev {
                    .testimonial-grid {
                        margin-right: 36px;
                        margin-left: 0px;
                        box-shadow: -40px 0px 50px 0px rgba(26, 46, 85, .1);
                    }
                }
                &.swiper-slide-next {
                    .testimonial-grid {
                        margin-left: 36px;
                        margin-right: 40px;
                        box-shadow: 70px 0px 50px 0px rgba(26, 46, 85, .1);
                    }
                }
            }
        }
    }
}