.why-choose-box-3-custom {
    &.features-box {
        box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.05);
        padding: 0;
        border-radius: 7px; 
        max-width: 1470px !important;
        margin: 0 auto;
        .thumbnail {
            img {
                border-radius: 5px 5px 0 0;
            }
        }
        .content {
            padding: 0 40px 45px;
        }
        .icon {
            margin-top: -40px;
            position: relative;
            height: 90px;
            width: 90px;
            font-size: 44px;
            color: var(--color-white);
        }
        .content {
            .title {
                margin-bottom: 22px;
            }
        }
        &.color-primary-style {
            .icon {
                background-color: rgba(26, 182, 157, 1);
            }
        }
        &.color-secondary-style {
            .icon {
                background-color: rgba(238, 74, 98, 1);
            }
        }
        &.color-extra08-style {
            .icon {
                background-color: rgba(70, 100, 228, 1);
            }
        }
    }
}

.user-baniere{
    max-height: 300px;
    width: 1470px !important;
    height: 300px;
    border-radius: 0px !important;
}

.user-icon{
    margin-bottom: 10px !important;
    background-color: rgb(82, 69, 69) !important;
    height: 230px !important;
    width: 230px !important;
}

// custom
.user-icon {
    @media only screen and (min-width: 992px) { 
        margin-top: -1250px !important;
        position: relative;
        z-index: 2;
    }
    @media only screen and (min-width: 552px) { 
        margin-top: -125px !important;
        position: relative;
        z-index: 2;
    }
}
// end

.enrolled-area{
    width: 100%;
}

.enrolled-left{
    width: 20%;
    float: left;
    left: 0px;
}
.enrolled-right{
    width: 70%;
    float: right;
    right: 0px;
}


// next-session

.banner-custom {
    background-image: url("../../images/next-session/fond4.png") ;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.banner-shadow{
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, rgba(30, 78, 112, 0.7), rgba(0, 255, 0, 0.7), rgba(0, 0, 255, 0.7)); /* Multi-colored shadow */
}

.next-session-banner-color{
    color: white !important;
}

.next-session-banner-icon{
    color:#fff !important;
}

// scroll bar
ul.scrollmenu {
    // overflow: auto;
    overflow-y: hidden;
    white-space: nowrap;
  }
  
  ul.scrollmenu li {
    display: inline-block;
  }

.nav-next-session-custom{
    display:list-item !important;
}


.edu-section-gap-custom {
    padding: 35px 0 120px;
    @media #{$md-layout} {
        padding: 95px 0 100px;
    }
    @media #{$sm-layout} {
        padding: 75px 0 80px;
    }
}

.breadcrumb-style-3-custom {
    padding: 5px 0 40px;
    @media #{$md-layout} {
        padding: 5px 0 70px;
    } 
    @media #{$sm-layout} {
        padding: 5px 0 70px;
    }
    .breadcrumb-inner {
        text-align: left;
        .page-title {
            width: 55%;
            @media only screen and (max-width: 991px) {
                width: 100%;
            }
            .title {
                font-size: 36px;
                margin-bottom: 4px;
                line-height: 1.4;
                @media #{$sm-layout} {
                    font-size: 30px;
                }
                @media #{$large-mobile} {
                    font-size: 26px;
                }
            }
        }
        .edu-breadcrumb {
            justify-content: flex-start;
            margin-bottom: 55px;
            @media #{$md-layout} {
                margin-bottom: 40px;
            }
            @media #{$sm-layout} {
                margin-bottom: 40px;
            }
        }
        .course-meta {
            justify-content: flex-start
        }
    }
    .shape-group {
        li.shape-2 {
            left: 7%;
            img {
                width: 160px;
            }
        }
    }
}

.sidebar-top-position-custom {
    @media only screen and (min-width: 992px) { 
        margin-top: -300px;
        position: relative;
        z-index: 2;
    }
}

.gap-tb-text-custom {
    @media only screen and (min-width: 992px) { 
        margin-top: -300px;
        position: relative;
        z-index: 2;
    }
    @media only screen and (min-width: 552px) { 
        margin-top: -300px;
        position: relative;
        z-index: 2;
    }
}

@media screen and (max-width: 991px) {
    .next-session-pricing-table-custom {
    margin-bottom: 50px;
    }
}

.ns-info{
    align-items: baseline !important;
    justify-content: left !important;
    text-align: left !important;
}

// .edu-course-widget.widget-course-summery .thumbnail:after
.ns-video::after{
    content: none !important;
}
.ns-video-area{
    border-radius: 5px !important;
}

