.edu-course:hover .invisble-on-hover {
    opacity: 0;
    visibility: hidden;
}

.video-prof-box {
    .thumb {
        position: relative;
        text-align: center;
        .popup-icon {
            cursor: pointer;
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            transform: translateY(-50%);
            margin: 0 auto;
            height: 60px;
            width: 60px;
            line-height: 62px;
            background-color: var(--color-white);
            border-radius: 50%;
            color:C;
            font-size: 18px;
            i {
                margin-left: 5px;
            }
            &:hover {
                background-color: var(--color-primary);
                color: var(--color-white);
            }
        }
    }
}

.utrains-gap-top {
    margin-top: 190px;
    @media #{$md-layout} {
        margin-top: 100px;
    }
    @media #{$sm-layout} {
       margin-top: 80px;
    }
}

.utrains-cta-gap-top {
    margin-top: 120px;
    @media #{$md-layout} {
        margin-top: 100px;
    }
    @media #{$sm-layout} {
       margin-top: 80px;
    }
}

.utrains-cta-gap-bottom {
    margin-bottom: 120px;
    @media #{$md-layout} {
        margin-bottom: 100px;
    }
    @media #{$sm-layout} {
       margin-bottom: 80px;
    }
}

.utrains-company-gap-equal {
    margin-top: 0px;
    margin-bottom: 200px;
    @media #{$md-layout} {
        margin-top: 100px;
    }
    @media #{$sm-layout} {
       margin-top: 80px;
    }
}

.utrains-exparties-gap-top {
    margin-top: 120px;
    @media #{$md-layout} {
        margin-top: 100px;
    }
    @media #{$sm-layout} {
       margin-top: 80px;
    }
}

.utrains-gap-bottom {
    margin-bottom: 200px;
    @media #{$md-layout} {
        margin-bottom: 100px;
    }
    @media #{$sm-layout} {
       margin-bottom: 80px;
    }
}

.utrains-testimonials-gap-v {
    margin-bottom: 100px;
    margin-top: 190px;
    @media #{$md-layout} {
        margin-bottom: 100px;
        margin-top: 100px;
    }
    @media #{$sm-layout} {
       margin-bottom: 80px;
       margin-top: 80px;
    }
}

.utrains-brand-grid-wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    position: relative;
    &:after {
        content: "";
        height: 1px;
        width: 100%;
        background-color: #f0f0f0;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
    }
    .brand-grid {
        padding: 18px 5px;
        text-align: center;
        border-right: 1px solid #f0f0f0;
        transition: var(--transition);
        &:first-child {
            border-left: 1px solid #f0f0f0;
        }
        &:nth-child(3n) {
            border-left: 1px solid #f0f0f0;
        }
        &:hover {
            box-shadow: var(--shadow-darker);
        }
    }
    
}

.utrains-company-area {
    .features-wrapper {
        display: flex;
        gap: 65px;
    }
    .features-list {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
        li {
                font-weight: var(--p-medium);
                font-family: var(--font-secondary);
                color: var(--color-heading);
                padding-left: 25px;
                position: relative;
                margin-bottom: 16px;
                margin-top: 16px;
                &:before {
                    content: "\e913";
                    font-family: "icomoon";
                    color: var(--color-secondary);
                    font-size: 19px;
                    position: absolute;
                    left: 0;
                    top: -3px;
                }
            
        }

    }
}


.utrains-headline.e-animated svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    transform: translate(-50%,-50%);
    overflow: visible;
}

.utrains-headline.e-animated svg path {
    stroke: var(--color-primary);
    stroke-width: 9px;
    fill: none;
    opacity: 0;
    stroke-dasharray: 0 1500;
    transition: .3s
}

.utrains-headline.e-animated svg path {
    animation: utrains-headline-dash forwards;
    animation-duration: var(--animation-duration,1.2s);
    animation-iteration-count: 1
}

.utrains-headline.e-animated svg path:nth-of-type(2) {
    animation-delay: calc(var(--animation-duration, 1.2s) / 2)
}

.utrains-headline.e-hide-highlight svg path {
    opacity: 1;
    stroke-dasharray: 1500 1500;
    animation: hide-highlight .4s forwards;
    animation-iteration-count: 1
}

@keyframes utrains-headline-dash {
    0% {
        stroke-dasharray: 0 1500;
        opacity: 1
    }

    to {
        stroke-dasharray: 1500 1500;
        opacity: 1
    }
}

@keyframes hide-highlight {
    0% {
        opacity: 1;
        stroke-dasharray: 1500 1500
    }

    to {
        opacity: 0;
        filter: blur(10px)
    }
}


.utrains-headline-text-wrapper {
    vertical-align: bottom
}

.utrains-headline-dynamic-wrapper {
    display: inline-block;
    position: relative
}

.utrains-headline-dynamic-wrapper .utrains-headline-dynamic-text {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0
}

.utrains-headline-dynamic-wrapper .utrains-headline-dynamic-text.utrains-headline-text-active {
    position: relative
}

.utrains-headline-animation-type-flip .utrains-headline-dynamic-wrapper {
    perspective: 300px
}

.utrains-headline-animation-type-flip .utrains-headline-dynamic-text {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: rotateX(180deg)
}

.discount {
    color: var(--color-secondary);
    font-weight: 600;
}

.utrains-section-gap-bottom {
    padding: 0 0 120px 0;
}

.utrains-advantage-section-gap {
    padding: 50px 0;
}

.utrains-banner-btn-video .video-btn i {
    text-align: center;
    height: 60px;
    width: 60px;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    line-height: 62px;
    background: #d4f1e6;
    color: var(--color-primary);
    border-radius: 50%;
    font-size: 18px;
    -webkit-transition: var(--transition);
    transition: var(--transition);
}

.utrains-banner-btn-video .video-btn span {
    font-size: var(--font-size-b1);
    font-weight: var(--p-medium);
    color: var(--color-black);
    padding-left: 10px;
}

.utrains-banner-btn-video {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: left;
    -webkit-justify-content: left;
    -ms-flex-pack: left;
    justify-content: left;
    list-style: none;
    padding-left: 0;
    justify-content: center;
}

.utrains-content-to-learning .single-item .icon {
    font-size: 50px;
    line-height: 1;
    margin-bottom: 25px;
}

.utrains-content-to-learning .single-item .icon.color-extra02 {
    color: var(--color-extra02);
}
.custom-h-100{
    height: 660px !important;
    @media #{$large-mobile} {
        height: 100vh !important;
    }
}

.custom-slide-prev {
    left: 50px;
}

.custom-slide-prev {
    border-radius: 50%;
    margin-left: auto;
    margin-right: 6px;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 60px !important;
    z-index: 1;
}

.custom-slide-next {
    right: 50px;
    border-radius: 50%;
    margin-left: auto;
    margin-right: 6px;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 60px !important;
    z-index: 1;
}

.custom-slide-controls {
    background-color: #fff;
    background-color: var(--color-white);
    color: #181818;
    color: var(--color-heading);
    cursor: pointer;
    display: inline-block;
    height: 60px;
    line-height: 60px;
    transition: all .4s ease-out;
    width: 60px;
}

.custom-slide-controls:hover {
    background-color: #1e4e70;
    background-color: var(--color-primary);
    color: #fff;
    color: var(--color-white);
    transition: all .4s ease-out;
}
