.bk-check-form-space{
    border-radius: 10px;
    margin-bottom: 50px;
    padding: 10px;
}
.bk-check-input-bottom{
    text-decoration: none;
    border-bottom: 1px solid #e5e5e5;
    margin: 0px 20px 20px 20px;
}
.bk-check-input{
    padding: 0 0 !important;
}
.bk-check-input>input[type=tel]{
    padding: 0 0 !important;
}
.bk-check-label{
    font-family: Roboto, Arial, sans-serif;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: .2px;
    line-height: 24px;
    color: rgb(32, 33, 36);
    font-weight: 400;
    margin: 20px 20px 0px 20px;
}
.bk-check-label-item{
    font-size: 12pt;
    font-family: 'docs-Roboto', Helvetica, Arial, sans-serif;
    letter-spacing: 0;
}
.bk-check-label-required{
    color: rgb(217, 48, 37);
    padding-left: .25em;
}
.bk-check-input>.PhoneInputCountry{
    border-bottom: none !important;
    padding-top: 3px !important;
}


/////////////////////////////////////
.bk-check-radio-input{
    opacity: 1 !important;
    width: 50px;
    height: 30px;
    margin-top: 10px;
}
.bk-check-label-step2{
    color: var(--color-heading);;
}
.bk-check-date{
    text-decoration: none;
    border-right: 0px;
    border-left: 0px;
    border-top: 0px;
    color: #e5e5e5;
}

.bk-check-radio-area-step3{
    margin-top: 8px;
}
.bk-check-radio-input-step3{
    opacity: 1 !important;
    width: 20px;
    height: 20px;
    margin-top: 3px;
    margin-left: 3px;
}

.bk-check-date:focus{
    border: none;
    border-bottom: 1px solid #e5e5e5;
    outline: none;
}

.bk-check-label-step3{
    margin-top: 10px;
    margin-bottom: 0px !important;
}
.testimonial-area-19 .gym-testimonial-activator-custom {
    margin: 0 -15px;
    position: relative;
    height: 90%;
}
.bk-check-testimonial-area-19-custom {
    background-color: var(--white) !important;
}
.bk-check-contact-us-area-custom {
    padding: 20px 0 0 !important; 
}
.bk-check-form-style-custom{
    margin-bottom: 60px !important;
}
.bs-time-area{
    display: flex !important; 
    justify-content: left !important;
    align-items: center;
    margin-left: 7px;
    border: 1px solid #e5e5e5 !important;
    border-radius: 5px 5px !important;
    max-width: 120px;
    height: 40px;
}
.bs-time-sec-cycle{
    border: 1px solid #e5e5e5 !important;
    background-color: var(--color-primary);
    opacity: 0;
    border-radius: 50% !important;
    height: 15px;
    width: 15px;
    
}

.bs-time-sec-cycle:hover{
    opacity: 1;
    transition: 100ms;
    cursor: pointer;
}

.bs-time-sec-cycle:focus{
    opacity: 1;
    
}

.bs-time-f-cycle{
    border: 1px solid #e5e5e5;
    border-radius: 50% !important;
    padding: 3px 3px;
    margin-left: 2px;
    margin-right: 2px;
    height: 23px;
    width: 22px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.t{
    display: flex !important; 
    justify-content: center !important;
    margin: 0 auto !important;
    height: 60px;
    margin-bottom: 10px;
}

.bs-step2-input{
    border: 1px solid #e5e5e5 !important;
    height: 40px !important;
    margin-top: 20px;
    border-radius: 5px !important;
    padding: 10px 10px !important;
    
}

.bs-step2-textarea{
    border: 1px solid #e5e5e5 !important;
    margin-top: 20px;
    border-radius: 5px !important;
    padding: 10px 10px !important;
}

.bs-session-step1-date{
    border: 1px solid #e5e5e5 !important;
    height: 40px !important;
    border-radius: 5px !important;
    padding: 10px 10px !important;
}

@media screen and (min-width: 440px) and (max-width: 1200px) {
    .bs-space-between-days {
        width: 300px;
    }
}

.back-button{
    color: var(--color-primary) !important;
    background-color: var(--edu-btn-color) !important;
    border: 1px solid var(--color-primary) !important;
    padding-right: 20px;

}
.back-button:hover{
    background-color: var(--color-primary) !important;
    color: var(--edu-btn-color) !important;
    border: none !important;
}


@media(max-width: 700px){
    .form-img-intro{
        opacity: 0 !important;
    }
}
.form-num-step{
    font-size: 15px;
    margin-bottom: 0;
    margin-top: 55px;
    font-weight: var(--p-semi-bold);
    color: var(--color-heading);
}
.return-fleche{
    padding-left: 6px;
    font-size: 15px;
}

.backgroundcheck-gap-top{
    margin-top: 30px;
}

