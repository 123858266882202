/*-------------------
    Banner Style
---------------------*/
.hero-style-15 {
    &.programming-banner {
        position: relative;
        min-height: 880px;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background-image: url(../../../images/banner/programming-bg.svg);
            background-repeat: no-repeat;
            background-position: center bottom;
            z-index: -1;
        }
        .banner-content {
            margin-top: -100px;
            @media #{$md-layout-1} {
                margin-top: 90px;
            }

            .shape-group {
                li {
                    &.shape-1 {
                        top: -102px;
                        left: -109px;
                        span {
                            width: 190px;
                            height: 190px;
                            display: inline-block;
                            border: 30px solid rgba(238, 74, 98, 0.071);
                            border-radius: 50%;
                        }
                    }
                    &.shape-2 {
                        left: -166px;
                        top: 17%;
                        animation: edublink-sm-x-move 3.5s alternate infinite linear;
                    }
                }
            }
            .programming-feature-area {
                display: flex;
                list-style: none;
                margin-bottom: -220px;
                padding-top: 190px;
                padding-left: 0;
                position: relative;
                z-index: 1;
                @media #{$md-layout-1} {
                    margin-bottom: 0;
                    padding-top: 40px;
                    justify-content: center;
                }
                @media #{$sm-layout} {
                    display: inline-block;
                    text-align: center;
                }

                .feature-course,
                .feature-instructior {
                    display: flex;
                    align-items: center;

                    .icon {
                        height: 80px;
                        width: 80px;
                        font-size: 32px;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin: 0;
                        background-color: var(--color-white);
                        box-shadow: var(--shadow-dark);
                        transition: var(--transition);

                        &.icon-course {
                            svg {
                                filter: brightness(0) saturate(100%) invert(49%) sepia(88%) saturate(4103%) hue-rotate(236deg) brightness(97%) contrast(115%);
                            }
                        }
                        &.icon-instructor {
                            svg {
                                filter: brightness(0) saturate(100%) invert(36%) sepia(30%) saturate(4306%) hue-rotate(329deg) brightness(104%) contrast(87%);
                            }
                        }
                    }
                    .content {
                        padding-left: 20px;
                        .title {
                            margin-bottom: 0;
                        }
                    }
                }
                .feature-course {
                    margin-right: 40px;
                    @media #{$sm-layout} {
                        margin-bottom: 40px;
                        margin-right: 0;
                    }
                }
            }
        }
        .banner-thumbnail {
            margin-right: -30px;
            margin-top: 40px;
            @media #{$sm-layout} {
                margin-top: 0;
            }
            .shape-group {
                li {
                    &.shape-3 {
                        top: 20px;
                        left: 120px;
                    }
                    &.shape-4 {
                        top: 55%;
                        left: 31%;
                        right: inherit;
                    }
                    &.shape-5 {
                        bottom: 75px;
                        left: 109px;
                        right: inherit;
                    }
                    &.shape-6 {
                        bottom: inherit;
                        right: -130px;
                        top: 50px;
                    }
                    &.shape-7 {
                        top: 151px;
                        right: -164px;
                        span {
                            width: 100px;
                            height: 100px;
                            border-radius: 50%;
                            display: inline-block;
                            border: 15px solid rgb(238, 74, 98);  
                        }
                    }
                    &.shape-8 {
                        top: 290px;
                        right: -108px;
                    }
                }
            }
        }
    }
    .shape-9 {
        position: absolute;
        right: 0;
        top: 40%;
        @media #{$smlg-device} {
            display: none;
        }
    }
}