.faq-style-7 {
    padding: 110px 0 0;
    position: relative;

    .edu-faq-content {
        padding-bottom: 120px;
        @media #{$smlg-device} {
            padding-bottom: 60px;
        }
    }

    .edu-faq-gallery {
        bottom: 0;
        z-index: 12;
            @media #{$laptop-device} {
                bottom: -15px;
            }
            @media #{$smlg-device} {
                bottom: -175px;
            }
            @media #{$md-layout-1} {
                bottom: 0;
            }
            .thumbnail-1 {
                margin-left: -260px;
                img {
                    border-radius: 0;
                }
                @media #{$smlg-device} {
                    margin-left: -100px;
                }
                @media #{$md-layout-1} {
                    text-align: center;
                    margin-left: 0;
                }
            }
        // bottom: 0;
        // @media #{$smlg-device} {
        //     left: 0;
        //     bottom: -8px;
        // }
        // .thumbnail-1 {
        //     margin-left: -277px;
        //     @media #{$custom-lg-device-two} {
        //         margin-left: -140px;
        //     }
        //     @media #{$custom-laptop-device} {
        //         margin-left: -60px;
        //     }
        //     @media #{$smlg-device} {
        //         margin-left: -70px;
        //     }
        //     @media #{$md-layout-1} {
        //         margin-left: 0;
        //     }
        //     img {
        //         border-radius: 0;
        //         max-width: 100%;
        //         position: relative;
        //         z-index: 9;
        //         @media #{$sm-layout} {
        //             min-height: 100%;
        //         }
        //     }
        //     @media #{$md-layout-1} {
        //         text-align: center;
        //     }
        // }
        .shape-group {
            li {
                &.shape-1 {
                    top: 251px;
                    left: -27px;
                    bottom: inherit;
                    z-index: 1;
                }
            }
        }
    }
    .faq-accordion {
        .accordion-item {
            box-shadow: none;
            .accordion-button.collapsed {
                box-shadow: var(--shadow-darker);
                border-bottom-right-radius: 10px;
                border-bottom-left-radius: 10px;
            }
            .accordion-body {
                box-shadow: var(--shadow-darker);
                border-bottom-right-radius: 10px;
                border-bottom-left-radius: 10px;
            }
        }
    }
    .row--45 > [class*="col"], 
    .row--45 > [class*="col-"] {
        @media #{$laptop-device} {
            padding-left: 40px;
            padding-right: 40px; 
        }   
    }
    .shape-group {
        li {
            &.shape-bg {
                left: 0;
                right: 0;
                bottom: -150px;
            }
        }
    }
}