.photography-banner {
    position: relative;
    z-index: 1;
    overflow: hidden;
    margin-top: -110px;
    @media (max-width:1300px) {
        min-height: 100%;
    }
    @media #{$sm-layout} {
        margin-top: -115px;
    }
    .swiper-slide {
        padding: 0;
        display:block;
        &:before {
            content: "";
            height: 100%;
            width: 100%;
            background: linear-gradient(182deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 25%);
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1;
        }
        img {
            height: 100%;
            transform: scale(1.4);
            transition: transform 4s linear;
            max-width: 100%;
            width: 100%;
            background-size: cover;
            background-position: 100%;
            object-fit: cover;
           @media #{$smlg-device} {
                min-height: 600px;
           } 
        }
        &.swiper-slide-duplicate-active,
        &.swiper-slide.swiper-slide-active {
        img {
            transform: scale(1);
            }
        }
    }
    .thumbnail-bg-content {
        .banner-content {
            max-width: 760px;
            width: 100%;
            position: absolute;
            left: 0;
            bottom: 280px;
            @media #{$custom-lg-device-six} {
                bottom: 174px;
            }
            @media #{$custom-laptop-device} {
                left: 20px;
                bottom: 120px;
            }
            @media #{$smlg-device} {
                max-width: 100%;
                bottom: 100px;
                text-align: center;
            }
            @media #{$md-layout-1} {
                bottom: 50px;
            }
            @media #{$sm-layout} {
                bottom: 70px;
            }
            .title {
                color: var(--color-white);
                font-weight: var(--p-extra-bold);
                opacity: 0;
                visibility: hidden;
                transform: translateY(60px);
                transition-delay: 1700ms;
            }
            p {
                color: var(--color-white);
                font-size: 18px;
                opacity: 0;
                visibility: hidden;
                transform: translateY(70px);
                transition-delay: 1900ms;
            }
            .banner-btn {
                opacity: 0;
                transform: translateY(80px);
                transition-delay: 2100ms;
            }
        }
    }
    .swiper-slide-active { 
        .thumbnail-bg-content {
            .banner-content{
                .subtitle {
                    opacity: 1;
                    visibility: visible;
                    transform: translateY(0px);
                }
                .title {
                    opacity: 1;
                    visibility: visible;
                    transform: translateY(0px); 
                }
                p {
                    opacity: 1;
                    visibility: visible;
                    transform: translateY(0px);
                }
                .banner-btn {
                    opacity: 1;
                    visibility: visible;
                    transform: translateY(0px);
                }
            }
        }
    }
    .hero-slider-bg-controls {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 10;
        @media #{$md-layout-1} {
            display: none;
        }

        
    }
    .swiper-slide-controls {
        display: inline-block;
        width: 60px;
        height: 60px;
        line-height: 60px;
        transition: all .4s ease-out;
        background-color: var(--color-white);
        color: var(--color-heading);
        i {
            font-size: 20px;
            line-height: 58px; 
        }
        cursor: pointer;

        &:hover {
            transition: all .4s ease-out;
            background-color: var(--color-primary);
            color: var(--color-white);
        }
    }
    .slide-prev,
    .slide-next {
        position: absolute;
        top: 50%;
        z-index: 1;
        width: 60px!important;
        margin-left: auto;
        margin-right: 6px;
        text-align: center;
        border-radius: 50%;
        @media (max-width:1450px) {
            display: none;
        }
    }
    .slide-next {
        right: 50px;
    }
    .slide-prev {
        left: 50px;
    }
    .pagination-box-wrapper {
        position: absolute;
        bottom: -120px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        @media #{$smlg-device} {
            display: none;
        }
        .pagination-box-wrap {
            height: 200px;
            width: 200px;
            background-color:  rgba(255, 255, 255, 0.902);
            border-radius: 50%;
            display: flex;
            justify-content: center;
            position: relative;

            &::before,
            &::after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                z-index: -1;
                bottom: 0;
                left: 0;
                transition: var(--transition);
                border-radius: 50%;
                border: 1px solid rgba(255, 255, 255, .1);
            }
            &::after {
                animation: ripple_2 3s linear 1s infinite;
            }
            &::before {
                animation: ripple_2 3s linear infinite;
            }
        }
    }
    .swiper-pagination-fraction {
        bottom: 132px;
        left: 0;
        width: 100%;
        color: var(--color-heading);
        font-size: 20px;
        font-family: var(--font-secondary);
        font-weight: var(--p-medium);
    }
    br {
        @media #{$small-mobile} {
            display: none;
        }
    }
}
  