/*-------------------------
    Spacing
-------------------------*/
.edu-section-gap {
    padding: 115px 0 120px;
    @media #{$md-layout} {
        padding: 95px 0 100px;
    }
    @media #{$sm-layout} {
        padding: 75px 0 80px;
    }
}

.gap-tb-text {
    padding: 115px 0 112px;
    @media #{$md-layout} {
        padding: 95px 0 92px;
    }
    @media #{$sm-layout} {
        padding: 75px 0 72px;
    }
}

.section-gap-equal {
    padding: 120px 0;
    @media #{$md-layout} {
        padding: 100px 0;
    }
    @media #{$sm-layout} {
        padding: 80px 0;
    }
}

.gap-top-text {
    padding-top: 115px;
    @media #{$md-layout} {
        padding-top: 95px;
    }
    @media #{$sm-layout} {
        padding-top: 75px;
    }
}

.gap-bottom-text {
    padding-bottom: 112px;
    @media #{$md-layout} {
        padding-bottom: 92px;
    }
    @media #{$sm-layout} {
       padding-bottom: 72px;
    }
}

.gap-top-equal {
    padding-top: 120px;
    @media #{$md-layout} {
        padding-top: 100px;
    }
    @media #{$sm-layout} {
       padding-top: 80px;
    }
}

.gap-bottom-equal {
    padding-bottom: 120px;
    @media #{$md-layout} {
       padding-bottom: 100px;
    }
    @media #{$sm-layout} {
       padding-bottom: 80px;
    }
}

//Large Section Specing

.section-gap-large {
    padding: 145px 0 150px;
    @media #{$lg-layout} {
        padding: 115px 0 120px;
    }
    @media #{$md-layout} {
        padding: 95px 0 100px;
    }
    @media #{$sm-layout} {
        padding: 75px 0 80px;
    }
}

.gap-large-text {
    padding: 145px 0 142px;
    @media #{$lg-layout} {
        padding: 115px 0 112px;
    }
    @media #{$md-layout} {
        padding: 95px 0 92px;
    }
    @media #{$sm-layout} {
        padding: 75px 0 72px;
    }
}

.gap-large-equal {
    padding: 150px 0;
    @media #{$lg-layout} {
        padding: 120px 0;
    }
    @media #{$md-layout} {
        padding: 100px 0;
    }
    @media #{$sm-layout} {
        padding: 80px 0;
    }
}

.gap-lg-top-text {
    padding-top: 145px;
    @media #{$lg-layout} {
        padding-top: 115px;
    }
    @media #{$md-layout} {
        padding-top: 95px;
    }
    @media #{$sm-layout} {
        padding-top: 75px;
    }
}
.gap-lg-bottom-text {
    padding-bottom: 142px;
    @media #{$lg-layout} {
        padding-bottom: 112px;
    }
    @media #{$md-layout} {
        padding-bottom: 92px;
    }
    @media #{$sm-layout} {
        padding-bottom: 72px;
    }
}

.gap-lg-bottom-equal {
    padding-bottom: 150px;
    @media #{$lg-layout} {
        padding-bottom: 120px;
    }
    @media #{$md-layout} {
        padding-bottom: 100px;
    }
    @media #{$sm-layout} {
        padding-bottom: 80px;
    }
}

.gap-lg-top-equal {
    padding-top: 150px;
    @media #{$lg-layout} {
        padding-top: 120px;
    }
    @media #{$md-layout} {
        padding-top: 100px;
    }
    @media #{$sm-layout} {
        padding-top: 80px;
    }
}

.brand-section-gap {
    padding: 85px 0;
}


// Margin
.mb--10 {
    margin-bottom: 10px;
}

.mb--20 {
    margin-bottom: 20px;
}

.mb--30 {
    margin-bottom: 30px;
}

.mb--40 {
    margin-bottom: 40px;
}

.mb--50 {
    margin-bottom: 50px !important;
}

.mb--60 {
    margin-bottom: 60px !important;
}

.mb--90 {
    margin-bottom: 90px !important;
}
.mt--50 {
    margin-top: 50px;
}

@media only screen and (min-width: 1200px) {
    .col-pl--35 {
        padding-left: 35px;
    }
    .col-pr--35 {
        padding-right: 35px;
    }
}

// Padding
.pt--50 {
    padding-top: 50px;
}