
.dropdown {
  position: relative;
  display: inline-block;
}
.rounded-circle {
  pointer-events: none;
  position: absolute;
  object-fit: cover;
}
.dropdown-toggle {
  color: white;
  padding: 10px 100px;
  border: none;
  cursor: default;
  overflow: hidden;
}
.dropdown-menu {
  display: block;
  position: absolute;
  background-color: white;
  box-shadow: 0 8px 16px rgba(140, 139, 139, 0.2);
  z-index: 1;
  list-style: none;
  padding: 10px 20px;
  margin: 0;
  width: 340px;
  border-radius: 10px;
  right: 100px;
}
.dropdown-menu::before {
  content: "";
  position: absolute;
  top: -5px;
  width: 20px;
  right: 20px;
  transform: rotate(45deg);
  background-color: var(--secondary-bg);
  height: 20px;
}
.dropdown-item {
  padding: 12px 16px;
  cursor: pointer;
  border-radius: 10px;
}
.dropdown-item:hover {
  background-color: #ddd;
}
.dropdown-item a {
  text-decoration: none;
  color: #333;
}
.userprofil {
  margin: 0;
  padding: 10px;
}
.man {
  display: flex;
  align-items: center;
}
.mane {
  margin: 0;
  display: block;
  align-items: center;
  position: relative;
  margin-left: 60px; /* Correction */

}
.manee {
  margin-top: 0;
}
.mb-1 {
  margin-bottom: 5px;
}
.userprofil h5 {
  margin: 0;
  font-size: 20px;
  font-weight: bold;
}
.userprofil h6 {
  margin: 0;
  font-size: 16px;
  font-weight: 400; /* Correction */
  line-height: 1.2rem;
  white-space: nowrap;
  // overflow: hidden;
  text-overflow: ellipsis;
}
.mane h6,
.mane span {
  margin: 0;
}
.userprofil span {
  color: #777;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.2rem;
}
.userprofil img {
  margin-right: 10px;
}

.dropdown-toggle-profile {
    color: white;
    padding: 0px 100px 10px 0px;
    border: none;
    cursor: default;
    overflow: hidden;
}