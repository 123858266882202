/*-------------------------
    Typography
-------------------------*/
html {
    overflow: hidden;
    overflow-y: auto;
    margin: 0;
    padding: 0;
    font-size: 10px;
}

body {
    overflow: hidden;
    font-size: var(--font-size-b1);;
    line-height: var(--line-height-b1);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: var(--font-primary);
    color: var(--color-body);
    font-weight: 400;
    background-color: var(--color-bg-body);
    margin: 0;
    padding: 0;
    &.modal-open {
        padding: 0 !important;
    }
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
address,
p,
pre,
blockquote,
menu,
ol,
ul,
table,
hr {
    margin: 0;
    margin-bottom: 20px;
}


.display-one {
    font-size: 112px;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    word-break: break-word;
    font-family: var(--font-secondary);
    color: var(--color-heading);
}

h1,
.h1 {
    font-size: var(--h1);
    line-height: var(--h1-lineHeight);
}

h2,
.h2 {
    font-size: var(--h2);
    line-height: var(--h2-lineHeight);
}

h3,
.h3 {
    font-size: var(--h3);
    line-height: var(--h3-lineHeight);
}

h4,
.h4 {
    font-size: var(--h4);
    line-height: var(--h4-lineHeight);
}

h5,
.h5 {
    font-size: var(--h5);
    line-height: var(--h5-lineHeight);
}

h6,
.h6 {
    font-size: var(--h6);
    line-height: var(--h6-lineHeight);
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    a {
        color: inherit;
        transition: 0.5s;
    }
}
a {
    color: var(--color-heading);
    transition: 0.3s;
}
@media #{$smlg-device} {
    h1,
    .h1 {
        font-size: 44px;
    }
}
@media #{$md-layout} {
    h1,
    .h1 {
        font-size: 38px;
    }
    h2,
    .h2 {
        font-size: 30px;
    }

    h3,
    .h3 {
        font-size: 24px;
    }

    h4,
    .h4 {
        font-size: 18px;
    }

}


@media #{$sm-layout} {
    h1,
    .h1 {
        font-size: 34px;
    }

    h2,
    .h2 {
        font-size: 26px;
    }

    h3,
    .h3 {
        font-size: 22px;
    }
    h4,
    .h4 {
        font-size: 18px;
    }
}


h1,
.h1,
h2,
.h2,
h3,
.h3 {
    font-weight: var(--p-bold);
}

h4,
.h4,
h5,
.h5,
h6,
.h6 {
    font-weight: var(--p-semi-bold);
}

p {
    font-size: var(--font-size-b1);;
    line-height: var(--line-height-b1);
    font-weight: 400;
    color: var(--color-body);
    margin: 0 0 30px;

    @media #{$sm-layout} {
        margin: 0 0 20px;
    }

    &.b1 {
        font-size: var(--font-size-b1);
        line-height: var(--line-height-b1);
    }

    &.b2 {
        font-size: var(--font-size-b2);
        line-height: var(--line-height-b2);
    }

}

.b1 {
    font-size: var(--font-size-b1);
    line-height: var(--line-height-b1);
}

.b2 {
    font-size: var(--font-size-b2);
    line-height: var(--line-height-b2);
}

.table,
table {
    border-collapse: collapse;
    border-spacing: 0;
    margin: 0 0 30px;
    width: 100%;
}

table a,
table a:link,
table a:visited {
    text-decoration: none;
}

cite,
.wp-block-pullquote cite,
.wp-block-pullquote.is-style-solid-color blockquote cite,
.wp-block-quote cite {
    color: var(--color-heading);
}

var {
    font-family: "Courier 10 Pitch", Courier, monospace;
}

/*---------------------------
    List Style 
---------------------------*/
ul,
ol {
    padding-left: 18px;
}

ul {
    list-style: square;
    margin-bottom: 30px;
    padding-left: 20px;

    &.liststyle {
        &.bullet {
            li {
                font-size: var(--font-size-b1);
                line-height: var(--line-height-b1);
                color: var(--color-body);
                position: relative;
                padding-left: 30px;

                @media #{$sm-layout} {
                    padding-left: 19px;
                }

                &::before {
                    position: absolute;
                    content: "";
                    width: 6px;
                    height: 6px;
                    border-radius: 100%;
                    background: var(--color-body);
                    left: 0;
                    top: 10px;
                }

                &+li {
                    margin-top: 8px;
                }
            }
        }
    }

    li {
        font-size: var(--font-size-b1);;
        line-height: var(--line-height-b1);
        margin-top: 10px;
        margin-bottom: 10px;
        color: var(--color-body);
        a {
            text-decoration: none;
            color: var(--color-body);
            transition: var(--transition);
            &:hover {
                color: var(--color-primary);
            }
        }
    }
    ul {
        margin-bottom: 0;
    }
}

ol {
    margin-bottom: 30px;
    li {
        font-size: var(--font-size-b1);;
        line-height: var(--line-height-b1);
        color: var(--color-body);
        margin-top: 10px;
        margin-bottom: 10px;
        font-weight: 400;
        a {
            color: var(--heading-color);
            transition: var(--transition);
            text-decoration: none;
            &:hover {
                color: var(--color-primary);
            }
        }
    }
    ul {
        padding-left: 30px;
    }
}

hr {
    display: block;
    height: 0;
    margin: 15px 0;
    border-top: 1px solid var(--color-border);
    background: transparent;
}
kbd {
    background: var(--color-primary);
}
