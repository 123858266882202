/*-------------------
  Hero Banner Styles
---------------------*/
.hero-style-18 {
    background: url(../../../images/bg/bg-image-48.webp);
    background-size: cover;
    background-position: center bottom;
    background-repeat: no-repeat;
    position: relative;
    padding: 155px 0 220px;
    @media #{$md-layout-1} {
        padding: 100px 0 170px;
    }

    &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: url(../../../images/bg/bg-image-47.webp);
        background-size: cover;
        background-position: center bottom;
        background-repeat: no-repeat;
    }
   .banner-content {
        .pre-title {
            font-size: 16px;
            font-weight: var(--p-semi-bold);
            text-transform: uppercase;
            letter-spacing: 4.5px;
        }
        .title {
            margin-top: 20px;
        }
        .shape-group {
            li {
                &.shape-1 {
                    left: -477px;
                    top: -438px;                
                    span {
                        border: 70px solid rgba(0, 0, 0, 0.03);
                        border-radius: 50%;
                        width: 568px;
                        height: 568px;
                        display: inline-block;
                    }
                }
            }
        }
    } 
    .banner-thumbnail {
        margin-right: -20px;
        margin-top: -35px;
        @media #{$md-layout-1} {
            margin-top: 0;
        }
        .thumbnail {
            img {
                border-radius: 220px;
            }
        }
        .instructor-info {
            bottom: 112px;
            @media #{$sm-layout} {
                display: none;
            }
            .inner {
                width: 290px;
                height: 150px;
                padding: 25px 29px;
                border-radius: 5px;
                .title-wrap {
                    display: flex;
                    .title {
                        font-weight: var(--p-bold);
                        font-size: 16px;            
                    }
                    .dot {
                        margin-left: 68px;
                        margin-top: -4px;
                        
                        span {
                            width: 4px;
                            height: 4px;
                            border-radius: 10px;
                            background-color: var(--color-body);
                            display: inline-block;
                        }
                    }
                }
                .media {
                    position: relative;
                    bottom: -10px;
                }
            }
        }
        .sales-info {
            position: absolute;
            bottom: -75px;
            @media #{$sm-layout} {
                display: none;
            }
            .inner {
                width: 290px;
                height: 174px;
                background-color: var(--color-white);
                border-radius: 5px;
                padding: 26px 30px 16px;
                box-shadow: 0px 30px 70px rgba(0, 0, 0, 0.15);

                .title-wrap {
                    display: flex;
                    justify-content: space-between;

                    .counter-item {
                        span {
                            font-size: 24px;
                            font-weight: var(--p-bold);
                            font-family: var(--font-secondary);
                            color: var(--color-primary);
                        }
                    }
                    .chart-wrap {
                        width: 40px;
                        height: 40px;
                        line-height: 40px;
                        background: #F2F2F2;
                        border-radius: 10px;
                        text-align: center;
                    }
                }
                p {
                    font-size: 15px;
                    margin-bottom: 0;
                    margin-top: -10px;
                }
                .media {
                    justify-content: space-between;
                    margin-top: 20px;
                    .single-progressbar {
                        .progressbar-five {
                            max-width: 170px;
                            margin-top: 35px;
                        }
                        .progressbar {
                            width: 100%;
                            height: 5px;
                            margin-top: 5px;
                            margin-bottom: 25px;
                            position: relative;
                    
                            &::before {
                                content: "";
                                position: absolute;
                                bottom: 0;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 10px;
                                border-radius: 5px;
                                background-color: #EDEDED;
                                z-index: 0;
                            }
                        }
                        .proggress {
                            height: 10px;
                            width: 10px;
                            background: var(--color-tertiary);
                            position: relative;
                            z-index: 1;
                        }
                        .percentCount {
                            float: right;
                            margin-top: -40px;
                            margin-right: -60px;
                            clear: both;
                            font-size: 15px;
                            font-family: var(--font-primary);
                            color: var(--color-black);
                        }
                    }
                }
            }
        }
        .shape-group {
            li {
                &.shape-2 {
                    top: -25px;
                    left: 132px;
                    z-index: 0               
                }
                &.shape-3 {
                    top: 41px;
                    left: 132px;             
                } 
            }
        }
    }
}