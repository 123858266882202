.hero-style-13 {
    min-height: 950px;
    background-image: linear-gradient(76.24deg, #1AB69D 0%, #31B978 100%);
    position: relative;
    top: -115px;
    .banner-image {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
    }
    @media #{$smlg-device} {
        min-height: 750px;    
    }
    @media #{$md-layout-1} {
        min-height: 100%;
    }
    .banner-content {
        @media #{$md-layout-1} {
            margin-top: 200px;
        }
        .title {
            color: var(--color-white);
        }
        p {
            color: var(--color-white);
        }
        .banner-btn {
            a {
                &.edu-btn {
                    background-color:  var(--color-white);
                    color: var(--color-heading);
                    &::after {
                        display: none;
                    }

                    &:hover {
                        background-color: var(--color-secondary);
                        color: var(--color-white);
                    }
                }
            }
        }
    }
    .banner-thumbnail {
        margin-right: -20px;
        margin-bottom: -120px;
        @media #{$custom-lg-device-eight} {
            margin-bottom: -183px;
        }
        @media #{$smlg-device} {
            margin-bottom: -135px;
        }
        @media #{$md-layout-1} {
            margin-bottom: 0;
            margin-right: 0;
        }
        .thumbnail {
            @media #{$md-layout-1} {
                text-align: center !important;
            }
        }
        .shape-group {
            li {
                &.shape-1 {
                    top: -95px;
                    left: 0;
                    span {
                         width: 650px;
                        height: 650px;
                        border-radius: 50%;
                        background-color: rgba(255, 255, 255, 0.051);
                        display: block;
                    }
                }
                &.shape-2 {
                    top: -24px;
                    left: 80px;
                    span {
                        width: 500px;
                        height: 500px;
                        border-radius: 50%;
                        background-color: rgba(255, 255, 255, 0.051);
                        display: block;
                    }
                }
                &.shape-3 {
                    top: inherit;
                    bottom: 67px;
                    left: -73px;
                }
                &.shape-4 {
                    top: inherit;
                    right: inherit;
                    left: 0;
                    bottom: 0;
                    z-index: -1;
                }
                &.shape-5 {
                    bottom: inherit;
                    right: -143px;
                    top: -26px;
                }
                &.shape-6 {
                    right: -176px;
                    top: 19px;
                    bottom: inherit;
                }
            }
        }
    }
    .shape-group {
        z-index: 1;
        li {
            &.shape-7 {
                right: -6px;
                top: 13%;
            }
            &.shape-8 {
                left: 0;
                right: 0;
                bottom: -2px;
            }
            &.shape-11 {
                left: 0;
                top: 0;
            }
        }
    }
}
