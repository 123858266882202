/*-------------------
  Brand Styles
---------------------*/
.brand-area-12 {
    background: linear-gradient(90deg, #1AB69D 0%, #31B978 100%);
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.05);

    .brand-grid-wrap {
        &.brand-style-12 {
            grid-template-columns: repeat(5, 1fr);
            @media #{$md-layout-1} {
                grid-template-columns: repeat(2, 1fr); 
            }
            @media #{$small-mobile} {
                grid-template-columns: repeat(1, 1fr);
            }

            &::after {
                display: none;
            }
            .brand-grid {
                border: none;
                padding: 48px 0;
                opacity: .4;
                &:first-child {
                    margin-left: -60px;
                    margin-top: 15px;
                    @media #{$md-layout-1} {
                        margin-left: 0;
                        margin-top: 0; 
                    }
                }
                &:nth-child(52){
                    margin-right: -120px;
                    @media #{$md-layout-1} {
                        margin-right: 0;
                    }
                }
                &:nth-child(3){
                    margin-right: -40px;
                    margin-top: 13px;
                    @media #{$md-layout-1} {
                        margin-right: 0;
                        margin-top: 0;
                    }
                }
                &:nth-child(4) {
                    margin-right: -85px;
                    margin-top: 20px;
                    @media #{$md-layout-1} {
                        margin-right: 0;
                        margin-top: 0;
                    }
                }
                &:nth-child(5){
                    margin-right: -120px;
                    @media #{$md-layout-1} {
                        margin-right: 0;
                    }
                }
                &:hover {
                    opacity: 1;
                    box-shadow: none;
                }
            }
        }
    }
}