/*-------------------
  FAQ Styles
---------------------*/
.faq-style-11 {
    .faq-accordion {
        .accordion-item {
            border-radius: 5px;
            .accordion-header {
                .accordion-button {
                    background-color: var(--color-white);
                    color: var(--color-black);
                    border-radius: 5px;
                    &::before {
                        color: var(--color-body);
                    }
                }
            }
            .accordion-body {
                padding: 5px 30px 30px;
            }
        }
    }
    .edu-faq-gallery {
        .faq-thumbnail {
            &.thumbnail-1 {
                position: relative;
                z-index: -1;
                img {
                    border-radius: 0;
                    border-top-left-radius: 230px;
                    border-top-right-radius: 230px;
                    border-bottom-right-radius: 200px;
                }
            }
        }
        .support-question {
            margin-top: -70px;
            @media #{$sm-layout} {
                display: none;
            }

            .inner {
                padding: 52px 67px;
                display: inline-block;
                background: var(--color-secondary);
                box-shadow: 0px 10px 70px 0px rgba(14, 4, 29, 0.2);
                border-top-left-radius: 65px;
                border-top-right-radius: 23px;
                border-bottom-right-radius: 107px;
                border-bottom-left-radius: 65px;
                position: relative;

                &::after {
                    content: "";
                    position: absolute;
                    top: -20px;
                    right: -20px;
                    width: 100%;
                    height: 100%;
                    background: var(--color-white);
                    border-top-left-radius: 65px;
                    border-top-right-radius: 23px;
                    border-bottom-right-radius: 107px;
                    border-bottom-left-radius: 65px;
                    z-index: -1;
                }
                .content {
                    .title {
                        font-size: 22px;
                        color: var(--color-white);
                        margin-bottom: 0;
                    }
                }
            }
        }
        .shape-group {
            li {
                &.shape-1 {
                    left: 48px;
                    top: 33px;
                    z-index: -2;
                }
                &.shape-2 {
                    left: 29px;
                    top: 74px;
                    z-index: 0;
                    right: inherit;
                }
            }
        }
    }
}