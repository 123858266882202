/*-------------------
  Features Styles
---------------------*/
.features-area-9 {
    background: var(--bg-color-2);
    .features-wrapper {
        position: relative;
        display: flex;
        width: 100%;
        height: 150px;
        margin-left: -95px;
        align-items: center;

        .feature-wrap {
            display: flex;
            align-items: center;
            animation: scroll 20s linear infinite; 
            animation-fill-mode: forwards;
            .features-track {
                display: flex;
                margin-left: 0;
                padding-top: 32px;
                padding-bottom: 32px;
                align-items: center;
                flex: 0 0 auto;
    
                .features-icon {
                    margin-left: 40px;
                    margin-right: 40px;
                }
                .feature-text {
                    color: var(--color-white);
                    font-size: 20px;
                    font-weight: var(--p-bold);
                    font-family: var(--font-secondary);
                    text-transform: uppercase;
                    @media #{$large-mobile} {
                        font-size: 15px;
                    }
                }
            }
        }
    }
}