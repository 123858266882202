.hero-style-17 {
    &.digital-marketing-banner {
        min-height: 930px;
        position: relative;
        top: -110px;
        margin-bottom: -110px;
        overflow: hidden;
        @media #{$smlg-device} {
            min-height: 630px;
        }
        @media #{$sm-layout} {
            min-height: 500px;
        }

        &::before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background: url(../../../images/bg/bg-image-45.svg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;

        }
        .banner-content {
            position: relative;
            z-index: 1;
            @media #{$small-mobile} {
                padding-top: 50px;
            }
            @media #{$md-layout-1} {
                margin-top: 20px;
               margin-bottom: 0; 
            }
            @media #{$sm-layout} {
                margin-top: 70px;
                margin-bottom: 0;
            }
            &::after {
                content: "";
                position: absolute;
                left: 20px;
                right: 0;
                top: -150px;
                bottom: 0;
                margin: 0 auto;
                width: 650px;
                height: 650px;
                border-radius: 50%;
                background-color: rgba(255, 255, 255, 0.302);
                z-index: -1;
                @media #{$sm-layout} {
                    display: none;
                }
            }
            .title {
                font-size: 80px;
                font-weight: var(--p-bold);
                font-family: var(--font-secondary);
                &.title-2 {
                    line-height: 1.875;
                    margin-left: 240px;
                    @media #{$smlg-device} {
                        margin-left: 100px;
                    }
                    @media #{$sm-layout} {
                        margin-left: 0;
                        line-height: 1.3;
                    }
                }
                @media #{$smlg-device} {
                    font-size: 50px;
                }
                @media #{$large-mobile} {
                    font-size: 40px;
                }
                @media #{$small-mobile} {
                    font-size: 36px;
                }
            }
            .instructor-info {
                position: absolute;
                top: 0;
                right: 10px;

                @media #{$md-layout-1} {
                    display: none;
                }

                .inner {
                    background-color: var(--color-secondary);
                    padding: 26px 40px 20px 30px;
                    box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.1);
                    border-top-left-radius: 45px;
                    border-top-right-radius: 15px;
                    border-bottom-right-radius: 75px;
                    border-bottom-left-radius: 45px;
                    position: relative;

                    &::before {
                        content: "";
                        position: absolute;
                        left: -24px;
                        right: 0;
                        top: -17px;
                        bottom: 0;
                        width: 320px;
                        height: 130px;
                        border: 2px solid rgba(255, 255, 255, 1);
                        border-top-left-radius: 65px;
                        border-top-right-radius: 20px;
                        border-bottom-right-radius: 100px;
                        border-bottom-left-radius: 65px;
                    }
                    .media {
                        display: flex;
                        .content {
                            line-height: 1;
                            color: var(--color-white);
                            font-weight: 500;
                            margin-top: 5px;
                            margin-left: 20px;
                        }
                    }
                }
            }
            .banner-btn {
                text-align: center;
            }
            .shape-group {
                li {
                    &.shape-1 {
                        left: -295px;
                        top: -170px;
                    }
                    &.shape-2 {
                        left: -135px;
                        top: -123px;
                    }
                    &.shape-3 {
                        left: -192px;
                        top: -62px;
                    }
                    &.shape-4 {
                        left: 7px;
                        top: 115px;
                    }
                    &.shape-5 {
                        right: -312px;
                        bottom: -135px;
                    }
                    &.shape-6 {
                        right: -144px;
                        bottom: -101px;
                    }
                    &.shape-7 {
                        right: -201px;
                        bottom: 20px;
                    }
                }
            }
        }
    }
    .shape-8 {
        position: absolute;
        top: 210px;
        right: 0;
        @media #{$smlg-device} {
            display: none;
        }
    }
    .shape-9 {
        position: absolute;
        bottom: 167px;
        left: -68px;
        @media #{$smlg-device} {
            display: none;
        }
        span {
            display: block;
            height: 270px;
            width: 270px;
            border: 2px solid var(--color-white);
            border-radius: 50%;
            opacity: .5;
        }
    }
    .shape-10 {
        position: absolute;
        top: 40px;
        right: -70px;
        @media #{$smlg-device} {
            display: none;
        }
        span {
            display: block;
            width: 274px;
            height: 274px;
            border: 2px solid var(--color-white);
            border-radius: 50%;
            opacity: .5;
        }
    }
}