/* styles.css */

.responsive-page {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 768px) {
    .responsive-page {
        flex-direction: column; /* Aligne les éléments en colonne */
        align-items: center; /* Centre les éléments horizontalement */
        text-align: center; /* Centre le texte */
       
    }

    .responsive-page .inner {
        margin: 0; /* Supprime les marges sur mobile */
        padding: 5px 0; /* Réduit l'espace vertical entre les textes */
    }

    .footer-link {
        margin: 0; /* Supprime les marges sur le lien */
        padding-bottom: 10px; /* Ajoute un peu d'espace en bas du lien */
    }

  
    .read-white {

        text-align: center;
        flex-direction: column;
    }
}
/* Dropdown.css */

/* Dropdown.css */

.dropdown-menu-home-page {
    list-style-type: none; 
    position: absolute; /* Pour permettre au dropdown de se superposer */
    background: white; /* Couleur de fond pour le dropdown */
    border: 1px solid #ccc; /* Bordure pour le dropdown */
    z-index: 1000; /* Assurez-vous qu'il est au-dessus des autres éléments */
}

.dropdown-menu-home-page  {
    list-style-type: none; 
    position: absolute; /* Permet au dropdown de se superposer */
    background: white; /* Couleur de fond pour le dropdown */
    border: 1px solid #ccc; /* Bordure */
    z-index: 1000; /* Assurez-vous qu'il est au-dessus des autres éléments */
    width: calc( - 20px); /* Prend toute la largeur moins 20px pour les marges */
    right: 20px; /* Aligne le dropdown avec une marge de 10px à gauche */
}



.header-action {
    display: flex; /* Utilise Flexbox pour aligner les éléments */
    align-items: center; /* Centre verticalement les éléments */
}

.heading-title {
    margin: 0; /* Supprime la marge par défaut */
    white-space: nowrap; /* Empêche le texte de se diviser */
    overflow: hidden; /* Cache le texte qui déborde */
    text-overflow: ellipsis; /* Ajoute des points de suspension pour le texte coupé */
    
}


/* styles.css */

