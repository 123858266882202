.utrains-header-action li {
    margin: 0 20px 0px 0px !important;
    line-height: 1;
}

a .utrains-btn-secondary {
    background: var(--color-secondary);
    color: var(--color-white);
    &:hover {
        color: var(--color-primary);
        background: var(--color-white);
    }
}