/*-------------------
  Testimonial Styles
---------------------*/
.home-one-testimonial {
    margin-top: 30px;
    @media #{$md-layout-1} {
        margin-top: 0px;
    }
}

.testimonial-heading-area {
    padding-right: 50px;
    @media #{$small-mobile} {
        padding-right: 0;       
    }
}
.testimonial-grid {
    box-shadow: var(--shadow-darker);
    background-color: var(--color-white);
    border-radius: 10px;
    padding: 60px 30px 50px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    &:after {
        content: url(../../images/testimonial/shape-1.png);
        position: absolute;
        top: -5px;
        right: -5px;
        z-index: -1;
    }
    .thumbnail {
        display: inline-block;
        position: relative;
        margin-bottom: 35px;
        img {
            border-radius: 50%;
        }
        .qoute-icon {
            height: 34px;
            width: 34px;
            line-height: 30px;
            font-size: 13px;
            color: var(--color-white);
            background-color: var(--color-primary);
            border-radius: 50%;
            border: 4px solid var(--color-white);
            display: block;
            text-align: center;
            position: absolute;
            bottom: -8px;
            right: -12px;
        }
    }
    .content {
        p {
            margin-bottom: 20px;
            font-size: 16px;
        }
        .rating-icon {
            margin-bottom: 6px;
            i {
                color: #f8b81f;
            }
        }
        .title {
            font-weight: var(--p-medium);
            margin-bottom: 2px;
        }
    }
    &.testimonial-style-3 {
        text-align: center;
        margin-bottom: 40px;
        margin-top: 30px;
        &:after {
            display: none;
        }
    }
}

.testimonial-slide {
    .content {
        background-color: var(--color-white);
        box-shadow: var(--shadow-darker);
        padding: 35px 28px 35px 40px;
        border-radius: 4px;
        margin-bottom: 30px;
        margin-top: 30px;
        .logo {
            margin-bottom: 22px;
        }
        p {
            color: var(--color-heading);
            font-size: 16px;
            margin-bottom: 15px;
        }
        .rating-icon {
            i {
                color: #f8b81f;
            }
        }
    }
    .author-info {
        display: flex;
        align-items: center;
        .thumb {
            margin-right: 20px;
            img {
                border-radius: 50%;
            }
        }
        .info {
            flex: 1;
            .title {
                margin-bottom: 0;

            }

        }
    }
    &.testimonial-style-2 {
        .content {
            padding: 45px 40px 50px 50px;
            border-radius: 5px;
            @media #{$small-mobile} {
                padding: 40px 30px; 
            }
            .rating-icon {
                margin-bottom: 20px;
            }
            p {
                margin-bottom: 24px;
            }
        }
    }
    &.testimonial-style-3 {
        .content {
            background-color: transparent;
            padding: 0;
            box-shadow: none;
            margin: 0;
            .rating-icon {
                margin-bottom: 15px;
            }
            p {
                margin-bottom: 20px;
            }
        }
    }
}

.testimonial-area-2 {
    position: relative;

    .testimonial-activation {
        padding: 0 30px;
        margin: -30px -30px 0;
        margin-bottom: 50px;

        .swiper-slide {
            opacity: 0;
            visibility: hidden;
            &.swiper-slide-active,
            &.swiper-slide-next,
            &.swiper-slide-next + .swiper-slide {
                opacity: 1;
                visibility: visible;
            }
            &.swiper-slide-next + .swiper-slide {

                @media #{$md-layout-1} {
                    opacity: 0;
                    visibility: hidden;
                }
            }
            &.swiper-slide-next {
                @media #{$sm-layout} {
                    opacity: 0;
                    visibility: hidden;
                }
            }
        }
    }
    .swiper-pagination {
        bottom: -70px;
    }

    .shape-group {
        .shape-1 {
            top: 168px;
            right: -75px;
        }
        .shape-2 {
            top: 217px;
            right: -106px;
        }
        .shape-3 {
            bottom: 50px;
            left: 45%;
            transform: translateX(-50%);
        }
    }
    &.edu-section-gap {
        .shape-group {
            .shape-3 {
                bottom: 58px;
            }
        }
    }
}

.testimonial-area-3 {
    position: relative;
    mask-image: url(../../images/others/mask-04.png);
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-size: cover;
    -webkit-mask-position: center;
    background-color: var(--color-lighten04);
    padding: 115px 0 250px;
    @media #{$md-layout} {
        padding: 95px 0 200px;
    }
    @media #{$sm-layout} {
        padding: 75px 0 180px;
    }
    .testimonial-activation-2 {
        margin: 0 -45px;
        padding: 0 30px;

        .testimonial-slide {
            .content {
                margin-top: 0;
                margin-bottom: 50px;
            }
        }
        .swiper-wrapper {
            .swiper-slide {
                opacity: 0;
                visibility: hidden;

                &.swiper-slide-active,
                &.swiper-slide-next {
                    opacity: 1;
                    visibility: visible;
                }
            }
            .swiper-slide {
                @media #{$md-layout-1} {
                    &.swiper-slide-next {
                       opacity: 0;
                    }
                }
            }
        }
    }
    .swiper-pagination {
        bottom: 235px;
        @media #{$md-layout-1} {
            bottom: 170px;
        }
    }
   .shape-group {
        @media #{$sm-layout} {
            display: none;
        }
        li {
            &.shape-1 {
                top: 95px;
                left: 95px;
            } 
            &.shape-2 {
                bottom: 215px;
                left: 245px;
            }
            &.shape-3 {
                bottom: 150px;
                right: 170px;
            }
            &.shape-4 {
                top: 120px;
                right: 70px;
            }
            &.shape-5 {
                bottom: 140px;
                left: 50%;
                transform: translateX(-50%);

                img {
                    transform: scaleX(1);
                    opacity: 1;
                }
            }
        }
    }
}

.testimonial-area-4 {
    padding: 50px 0 70px;
    position: relative;
    @media #{$md-layout-1} {
        padding: 90px 0 50px;
    }
    .testimonial-heading-area {
        @media #{$md-layout-1} {
            padding-right: 0;
        }
        .section-title {
            &.section-left {
                @media #{$md-layout-1} {
                    text-align: center;
                }
            }
        }
    }
    .testimonial-activation-3 {
        margin: 0 -50px;
        padding: 0 30px;
        margin-top: -30px;
        margin-bottom: 10px;

        .swiper-slide {
            opacity: 0;
                visibility: hidden;

                &.swiper-slide-active,
                &.swiper-slide-next {
                    opacity: 1;
                    visibility: visible;
                }
        }

    }
    .shape-group {
        li {
            img {
                opacity: .2;
            }
            &.shape-1 {
                bottom: 60px;
                left: 42%;
            }
            &.shape-2 {
                bottom: 35px;
                right: 12%;
                img {
                    opacity: .7;
                }
            }
        }
    }
    .swiper-navigation {
        text-align: left;
        @media #{$md-layout-1} {
            text-align: center;
        }
    }
}
.testimonial-area-8 {
    position: relative;
    &::before {
        position: absolute;
        content: "";
        left: 0;
        bottom: 0;
        right: 0;
        z-index: -1;
        background-image: url(../../images/bg/h8-testimonial-bg.svg);
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center bottom;
    }
    .testimonial-activation {
        padding: 0 30px;
        margin: -30px -30px 0;
        margin-bottom: 50px;

        .swiper-slide {
            opacity: 0;
            visibility: hidden;
            &.swiper-slide-active,
            &.swiper-slide-next,
            &.swiper-slide-next + .swiper-slide {
                opacity: 1;
                visibility: visible;
            }
            &.swiper-slide-next + .swiper-slide {

                @media #{$md-layout-1} {
                    opacity: 0;
                    visibility: hidden;
                }
            }
            &.swiper-slide-next {
                @media #{$sm-layout} {
                    opacity: 0;
                    visibility: hidden;
                }
            }
        }
    }

    .testimonial-slide {
        margin-top: 93px;
        .author-info {
            display: block;

            .thumb {
                margin-right: 0;
                margin-top: -100px;
                margin-bottom: 35px;
            }
        }
        .content {
            text-align: center;
            margin-bottom: 45px;
            border-radius:10px;
            p{
                margin-bottom: 30px;
            }
            .info {
                .title {
                    margin-top: 5px;
                    margin-bottom: 5px;
                }
            }
        }
    }

    .shape-group {
        li {
            &.shape-3 {
                bottom: 109px;
                left: 50%;
                z-index: -1;
                transform: translateX(-50%);
            }
        }
    }
    .swiper-horizontal>.swiper-pagination-bullets, 
    .swiper-pagination-bullets.swiper-pagination-horizontal, 
    .swiper-pagination-custom, .swiper-pagination-fraction {
        bottom: -30px;
    }
}
.testimonial-area-9  {
    background: var(--color-lighten01);

    .testimonial-heading-area {
        width: 380px;
        padding-right: 0;
        padding-top: 50px;
        position: relative;
        z-index: 12;
        @media #{$md-layout-1} {
            width: 100%;
            text-align: center;
            padding-top: 0;
        }
        .section-left {
            @media #{$md-layout-1} {
                text-align: center;
            }
        }
    }
    .bg-thumbnail {
        @media #{$md-layout-1} {
            text-align: center;
        }
        .shape-group {
            li {
                &.shape-3 {
                    left: -41px;
                    bottom: -87px;
                }
                &.shape-4 {
                    top: -80px;
                    right: -105px;
                }
                &.shape-5 {
                    left: -54px;
                    bottom: -18px;
                    z-index: 1;
                }
            }
        }
    }
    .health-bg-thumbnail {
        margin-left: 34px;
        @media #{$md-layout-1} {
            margin-left: 0;
        }
    }
    .home-health-testimonial-activator {
        margin-top: 110px;
        padding-left: 20px;
        @media #{$lg-layout} {
            margin-top: 0;
        }
        @media #{$md-layout-1} {
            margin-top: 0;
            padding-left: 0;
            text-align: center;
        }
        .testimonial-grid {
            background-color: transparent;
            box-shadow: none;
            &::after {
                display: none;
            }
            .thumbnail {
                margin-bottom: 25px;
            }
            .content {
                .title {
                    font-weight: var(--p-semi-bold);
                }
                p {
                    color: var(--color-heading);
                }
            }
        }
        .swiper-navigation {
            position: relative;
            bottom: 25px;
            left: 44px;
            z-index: 12;
            @media #{$md-layout-1} {
                left: 0;
                bottom: 20px;
            }
            .swiper-btn-prv,
            .swiper-btn-nxt {
                line-height: 57px;
                img {
                    @media #{$sm-layout} {
                        width: 20px;
                    }
                }
                &:hover {
                    img {
                        filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(321deg) brightness(101%) contrast(103%);
                    }
                }
                @media #{$sm-layout} {
                    line-height: 43px;
                }
            }
        }
    }
    .shape-group {
        li {
            &.shape-1 {
                bottom: 0px;
                left: -169px;
            }
            &.shape-2 {
                top: -91px;
                right: -150px;
            }
        }
    }
}
.testimonial-area-12 {
    background: var(--color-lighten01);
    overflow: hidden;
    .testimonial-heading-area {
        padding-right: 0;
        position: relative;
        z-index: 12;
        @media #{$md-layout-1} {
            width: 100%;
            text-align: center;
            padding-top: 0;
        }
        .section-left {
            @media #{$md-layout-1} {
                text-align: center;
            }
        }
    }
    .testimonial-grid {
        background-color: transparent;
        box-shadow: none;
        padding: 10px 25px 40px 0;
        margin-left: -15px;
        @media #{$md-layout-1} {
            padding: 0 0 40px 0;
            margin-left: 0;
        }
        &::after {
            display: none;
        }
        .content {
            p {
                font-size: 22px;
                color: var(--color-heading);
                font-weight: var(--p-medium);
                line-height: 1.63;
            }
            .author-info {
                display: flex;
                align-items: center;
                justify-content: left;

                @media #{$md-layout-1} {
                    justify-content: center;
                }
                .thumb {
                    margin-right: 20px;
                    img {
                        border-radius: 50%;
                    }
                }
                .info {
                    .title {
                        font-weight: var(--p-semi-bold);
                    }
                }
            }
        }
    }
    .bg-thumbnail {
        margin-bottom: -91px;
        @media #{$md-layout-1} {
            text-align: center;
         }
    }
    .shape-group {
        li {
            &.shape-3 {
                right: 33px;
                top: 262px;            
            }
            &.shape-4 {
                top: 154px;
                right: 11px;
            }
            &.shape-5 {
                bottom: 0;
                left: -95px;            
            }
        }
    }
    .shape-group {
        li {
            &.shape-1 {
                top: 14px;
                left: -259px;
            }
            &.shape-2 {
                top: -271px;
                left: -495px;
                span {
                    display: block;
                    height: 400px;
                    width: 400px;
                    border: 1px solid var(--color-border);
                    border-radius: 50%;
                }
            }
            &.shape-6 {
                left: -50px;
                top: 242px;
            }
        }
    }
    .swiper-navigation {
        .swiper-btn-nxt,
        .swiper-btn-prv {
            cursor: pointer;
            &:hover {
                img {
                    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(951%) hue-rotate(199deg) brightness(113%) contrast(104%);
                }
            }
        }
    }
}
  
.testimonial-coverflow {
    margin: -55px 0;
    .testimonial-grid {
        margin: 55px 0;
        position: relative;
        @media #{$large-mobile} {
            margin: 55px 30px;
        }
        &:before {
            content: "";
            height: 100%;
            width: 100%;
            background-color: var(--color-white);
            opacity: .7;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1;
            transition: var(--transition);
        }
    }
    .swiper-slide {
        padding: 0;

        &.swiper-slide-visible {
            &.swiper-slide-active {
               .testimonial-grid {
                    &:before {
                        opacity: 0;
                    }
               }
            }
        }
    }
}

.swiper-testimonial-slider-wrapper {
    .swiper-slide {
        opacity: 0;
    }
    .swiper-slide {
        &.swiper-slide-visible {
            opacity: 1;
            &.swiper-slide-prev {
                .testimonial-grid {
                    margin-left: 36px;
                    box-shadow: 70px 0px 50px 0px rgba(26, 46, 85, .1);
                    padding: 40px 30px 50px;
                    @media #{$smlg-device} {
                        margin-left: 0px;
                    }
                    @media #{$md-layout} {
                        margin-left: 40px;
                    }
                }
            }
            &.swiper-slide-next {
                .testimonial-grid {
                    margin-left: 40px;
                    margin-right: 36px;
                    box-shadow: -40px 0px 50px 0px rgba(26, 46, 85, .1);
                    padding: 40px 30px 50px;
                    @media #{$smlg-device} {
                        margin-left: 0px;
                        margin-right: 0px;
                    }
                    @media #{$md-layout} {
                        margin-left: 40px;
                    }
                }
            }
        }
    }
}

.testimonial-area-1 {
    margin-bottom: -40px;
    @media #{$smlg-device} {
        margin-top: 0px;
    }
    .home-one-testimonial-activator {
        padding: 0 30px;
        margin: 0 -45px;
        margin-top: -20px;

        .swiper-wrapper {
            .swiper-slide {
                opacity: 0;
                visibility: hidden;

                &.swiper-slide-active,
                &.swiper-slide-next {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }

        .testimonial-grid {
            margin: 30px 0px 50px 0px;
        }
    }
}


.swiper-pagination {
    .swiper-pagination-bullet {
        height: 7px;
        width: 7px;
        background-color: rgba(26, 182, 157, .3);
        opacity: 1;
        margin: 0 8px !important;
        &.swiper-pagination-bullet-active {
            background-color: rgba(26, 182, 157, 1);
        }
    }
}

.testimonial-area-6 {
    position: relative;
    &:before {
        content: "";
        height: 925px;
        width: 100%;
        background: linear-gradient(-90deg, #f5f1eb 0%, rgba(245, 241, 235, .3) 100%);
        clip-path: polygon(0 10%, 100% 0%, 100% 100%, 0% 100%);
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
        @media #{$md-layout} {
            height: 55%;
        }
        @media #{$sm-layout} {
            height: 55%;
        }
    }
    .testimonial-activation-5 {
        .swiper-wrapper {
            .swiper-slide {
                padding: 0;
            }
        }
    }
   
    .swiper-pagination {
        bottom: 30px;
        text-align: right;
    }

    .shape-group {
        li {
            &.shape-1 {
                bottom: 80px;
                left: -100px;
            } 
            &.shape-2 {
                top: -50px;
                right: -94px;
            }
            &.shape-3 {
                top: -200px;
                right: -250px;
                span {
                    height: 400px;
                    width: 400px;
                    border: 1px solid #e8e6e3;
                    border-radius: 50%;
                    display: block;
                }
            }
        }
    }
}
.testimonial-area-10 {
    background: var(--bg-color);
    overflow: hidden;

    .testimonial-heading-area {
        padding-right: 0;
    }
    .home-language-testimonial-activator {
        max-width: 770px;
        .testimonial-grid {
            box-shadow: none;
            border-radius: 5px;
            text-align: center;
            padding: 45px 64px 80px;
            @media #{$small-mobile} {
                padding: 45px 30px 80px;
            }
            &::after {
                display: none;
            }
            .content {
                .rating-icon {
                    margin-bottom: 15px;
                }
                p {
                    color: var(--color-heading);
                }
                .title {
                    font-weight: var(--p-semi-bold);
                }
            }
        }
    }
    .testimonial-thumb-wrap {
        margin: -56px auto 0;
        width: 320px;
        text-align: center;
        max-width: 100%;

        .nav-thumb {
            .clint-thumb {
                background: none;
                transform: scale(0.6667);
                transition: all .5s ease 0s;
                img {
                    border-radius: 50%;
                    height: auto;
                    max-width: 100%;
                    vertical-align: top;
                    cursor: pointer;
                }
            }
            &.swiper-slide-active {
                .clint-thumb {
                    transform: scale(1);
                    border-radius: 50%;
                }
            }
        }
        .swiper-slide {
            padding: 0;
            opacity: 0;
            visibility: hidden;
            &.swiper-slide-prev,
            &.swiper-slide-active,
            &.swiper-slide-next {
                opacity: 1;
                visibility: visible;
            } 
            &.swiper-slide-prev,
            &.swiper-slide-next {
                @media (max-width:499px) {
                    visibility: hidden;
                    opacity: 0;
                }
            }
        }
    }
    .shape-group {
        li {
            &.shape-1 {
                left: 120px;
                top: 145px;
            }
            &.shape-2 {
                left: 85px;
                top: 43%;
                span {
                    width: 70px;
                    height: 70px;
                    background: var(--color-tertiary);
                    border-radius: 100%;
                }
            }
            &.shape-3 {
                left: -55px;
                top: 13px;
                span {
                    display: block;
                    height: 400px;
                    width: 400px;
                    border: 1px solid var(--color-border);
                    border-radius: 50%;
                }
            }
            &.shape-4 {
                right: -131px;
                top: 51px;
                z-index: 1;
            }
            &.shape-5 {
                right: -467px;
                top: -256px;
                span {
                    display: block;
                    height: 470px;
                    width: 470px;
                    border: 1px solid var(--color-border);
                    border-radius: 50%;
                }
            }
        }
    }
}
.testimonial-area-11 {
    z-index: 1;
    .home-remote-testimonial-activator {
        max-width: 870px;
        padding: 0 30px;
        margin-top: -35px;
        @media #{$large-mobile} {
            padding: 0 10px;
        }

        .swiper-slide {
            opacity: 1;
            visibility: visible;
            &.swiper-slide-prev,
            &.swiper-slide-next {
                opacity: 0;
                visibility: hidden;
            }
        }
    }
    .testimonial-slide {
        margin-top: 93px;
        .author-info {
            display: block;

            .thumb {
                margin-right: 0;
                margin-top: -100px;
                margin-bottom: 35px;
            }
        }
        .content {
            width: 770px;
            text-align: center;
            margin-bottom: 45px;
            border-radius:10px;
            padding: 40px 95px;
            @media #{$md-layout-1} {
                width: 100%;
                padding: 40px 50px;
            }
            @media #{$small-mobile} {
                padding: 40px 20px;
            }
            p{
                margin-bottom: 20px;
            }
            .info {
                .title {
                    margin-bottom: 5px;
                }
                .rating-icon {
                    margin-top: 3px;
                }
            }
        }
    }
    .swiper-horizontal>.swiper-pagination-bullets, 
    .swiper-pagination-bullets.swiper-pagination-horizontal, 
    .swiper-pagination-custom, .swiper-pagination-fraction {
        bottom: -30px;
    }
    .shape-group {
        li {
            &.shape-1 {
                left: 107px;
                bottom: -50px;
            }
            &.shape-2 {
                left: 70px;
                bottom: 29px;
                span {
                    width: 70px;
                    height: 70px;
                    background: var(--color-tertiary);
                    border-radius: 100%;
                }
            }
            &.shape-3 {
                left: -28px;
                bottom: -235px;
                z-index: -1;
                span {
                    display: block;
                    height: 400px;
                    width: 400px;
                    border: 1px solid var(--color-border);
                    border-radius: 50%;
                }
            }
            &.shape-4 {
                right: -131px;
                top: 51px;
                z-index: 1;
            }
            &.shape-5 {
                right: -362px;
                top: -120px;
            }
        }
    }
}
.testimonial-area-13 {
    position: relative;
    .programming-testimonial-activation {
        margin: 0 -45px;
        padding: 0 30px;
        .testimonial-slide {
            .content {
                margin-bottom: 50px;
            }
        }

        .swiper-wrapper {
            .swiper-slide {
                opacity: 0;
                visibility: hidden;

                &.swiper-slide-active,
                &.swiper-slide-next {
                    opacity: 1;
                    visibility: visible;
                }
            }
            .swiper-slide {
                @media #{$md-layout-1} {
                    &.swiper-slide-next {
                       opacity: 0;
                    }
                }
            }
        }
    }
    .swiper-navigation {
        text-align: center;
        .swiper-btn-prv,
        .swiper-btn-nxt {
            box-shadow: var(--shadow-dark);
            &:hover {
                img {
                    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(321deg) brightness(101%) contrast(103%);
                }
            }
        }
    }
    .shape-group {
        li {
            &.shape-1 {
                top: -27px;
                left: -223px;
            }
            &.shape-2 {
                left: 27%;
                top: 87px;
                span {
                    width: 550px;
                    height: 550px;
                    display: block;
                    background: linear-gradient( 0deg, rgba(240,244,245,0.2) 0%, rgb(240,244,245) 100%);
                    border-radius: 50%;
                }
            }
            &.shape-3 {
                right: -61px;
                top: 142px;
            }
            &.shape-4 {
                right: -136px;
                top: 225px;
            }
        }
    }
    .shape-group {
        li {
            &.shape-5 {
               left: 0;
               top: -340px;
            }
        }
    }
}   
.testimonial-area-14 {
    position: relative;
    .online-art-testimonial-activation {
        margin: 0 -45px;
        padding: 0 30px;
        .testimonial-slide {
            &.testimonial-style-2 {
                .content {
                    margin-bottom: 50px;
                    border-top-left-radius: 50px;
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 100px;
                    border-bottom-left-radius: 5px;
                    .author-info {
                        @media #{$small-mobile} {
                            display: block;
                        }
                    }
                    .rating-icon {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .swiper-wrapper {
            .swiper-slide {
                opacity: 0;
                visibility: hidden;

                &.swiper-slide-active,
                &.swiper-slide-next {
                    opacity: 1;
                    visibility: visible;
                }
            }
            .swiper-slide {
                @media #{$md-layout-1} {
                    &.swiper-slide-next {
                       opacity: 0;
                    }
                }
            }
        }
    }
    .swiper-navigation {
        text-align: center;
        .swiper-btn-prv,
        .swiper-btn-nxt {
            box-shadow: var(--shadow-dark);
            &:hover {
                img {
                    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(321deg) brightness(101%) contrast(103%);
                }
            }
        }
    }
    .shape-group {
        li {
            &.shape-1 {
                top: 157px;
                left: -70px;
            }
            &.shape-2 {
                right: inherit;
                top: 240px;
                left: -93px;
                z-index: 1;
            }
            &.shape-3 {
                left: 27%;
                top: 105px;
                right: inherit;
                span {
                    width: 550px;
                    height: 550px;
                    display: block;
                    background: linear-gradient( 0deg, rgba(246, 241, 234, .2) 0%, rgba(246, 241, 234, .7) 100%);
                    border-radius: 50%;
                }
            }
        }
    }
    .shape-group {
        li {
            &.shape-5 {
               left: 0;
               top: -340px;
            }
        }
    }
} 
.photography-testimonial {
    padding-bottom: 260px;
    z-index: 0;
    .photography-testimonial-activator {
        max-width: 800px;
        .testimonial-grid {
            background-color: transparent;
            box-shadow: none;
            border-radius: 5px;
            text-align: center;
            padding: 40px 0px 80px;
            @media #{$small-mobile} {
                padding: 40px 30px 80px;
            }
            &::after {
                display: none;
            }
            .content {
                .quote-icon {
                    img {
                        margin-top: -63px;
                        filter: brightness(0) saturate(100%) invert(49%) sepia(99%) saturate(345%) hue-rotate(121deg) brightness(97%) contrast(94%);
                    }
                }
                p {
                    font-size: 22px;
                    color: var(--color-heading);
                }
                .title {
                    font-weight: var(--p-semi-bold);
                }
            }
        }
    }
    .photo-testimonial-thumb-wrap {
        margin: -56px auto 0;
        max-width: 320px;
        text-align: center;
        width: 100%;

        .nav-thumb {
            .clint-thumb {
                background: none;
                transform: scale(0.6667);
                transition: all .5s ease 0s;
                img {
                    border-radius: 50%;
                    height: auto;
                    max-width: 100%;
                    width: 100%;
                    vertical-align: top;
                    cursor: pointer;
                }
            }
            &.swiper-slide-active {
                .clint-thumb {
                    transform: scale(1);
                    border-radius: 50%;
                }
            }
        }
        .swiper-slide {
            padding: 0;
            opacity: 0;
            visibility: hidden;
            &.swiper-slide-prev,
            &.swiper-slide-active,
            &.swiper-slide-next {
                opacity: 1;
                visibility: visible;
            } 
            &.swiper-slide-prev,
            &.swiper-slide-next {
                @media (max-width:499px) {
                    visibility: hidden;
                    opacity: 0;
                }
            }
        }
    }
    .shape-group {
        li {
            &.shape-1 {
                left: -78px;
                bottom: -228px;
                top: inherit;
            }
            &.shape-2 { 
                left: 46%;
                top: 38%;
            }
            &.shape-3 {
                left: -695px;
                top: -261px;
            }
        }
    }
    .swiper-slide-controls {
        display: inline-block;
        width: 60px;
        height: 60px;
        line-height: 60px;
        transition: all .4s ease-out;
        background-color: var(--color-white);
        color: var(--color-heading);
        i {
            font-size: 20px;
            line-height: 58px; 
        }
        cursor: pointer;

        &:hover {
            transition: all .4s ease-out;
            background-color: var(--color-primary);
            color: var(--color-white);
        }
    }
    .slide-prev,
    .slide-next {
        position: absolute;
        top: 50%;
        z-index: 1;
        width: 60px!important;
        margin-left: auto;
        margin-right: 6px;
        text-align: center;
        border-radius: 50%;
        @media #{$md-layout-1} {
            display: none;
        }
    }
    .slide-next {
        right: 15px;
    }
    .slide-prev {
        left: 15px;
    }
}
.digital-marketing-testimonial {
    z-index: 0;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: url(../../images/bg/bg-image-46.svg);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .testimonial-heading-area {
        padding-right: 0;
        .section-title {
            margin-bottom: 0;
        }
    }
    .digital-marketing-activator {
        max-width: 960px;
        .testimonial-grid {
            background-color: transparent;
            box-shadow: none;
            border-radius: 5px;
            text-align: center;
            padding: 40px 0px 80px;
            @media #{$small-mobile} {
                padding: 40px 30px 80px;
            }
            &::after {
                display: none;
            }
            .content {
                p {
                    font-size: 18px;
                    color: var(--color-heading);
                }
                .title {
                    font-weight: var(--p-semi-bold);
                }
            }
        }
    }
    .digital-marketing-testimonial-thumbs {
        margin: -56px auto 0;
        max-width: 320px;
        text-align: center;
        width: 100%;

        .nav-thumb {
            .clint-thumb {
                background: none;
                transform: scale(0.6667);
                transition: all .5s ease 0s;
                img {
                    border-radius: 50%;
                    height: auto;
                    max-width: 100%;
                    width: 100%;
                    vertical-align: top;
                    cursor: pointer;
                }
            }
            &.swiper-slide-active {
                .clint-thumb {
                    transform: scale(1);
                    border-radius: 50%;
                }
            }
        }
        .swiper-slide {
            padding: 0;
            opacity: 0;
            visibility: hidden;
            &.swiper-slide-prev,
            &.swiper-slide-active,
            &.swiper-slide-next {
                opacity: 1;
                visibility: visible;
            } 
            &.swiper-slide-prev,
            &.swiper-slide-next {
                @media (max-width:499px) {
                    visibility: hidden;
                    opacity: 0;
                }
            }
        }
    }
    .shape-group {
        li {
            &.shape-1 {
                left: -248px;
                top: -21px;
            }
            &.shape-2 { 
                left: -193px;
                top: 27px;
            }
            &.shape-3 {
                right: -225px;
                bottom: 75px;
            }
            &.shape-4 {
                right: -178px;
                bottom: 21px;
            }
        }
    }
}

.business-testimonial {
    padding: 160px 0 100px;
    position: relative;
    &::before,
    &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }
    &::before {
        background-image: url(../../images/bg/business-testimonial-bg-1.svg);
    }
    &::after {
        background-image: url(../../images/bg/business-testimonial-bg-2.svg);
    }

    .testimonial-heading-area {
        position: relative;
        z-index: 2;
    }
    .business-testimonial-activation {
        margin: 0 -50px;
        padding: 0 30px;
        margin-top: -30px;
        margin-bottom: 10px;
        position: relative;
        z-index: 2;
        .testimonial-slide {
            .content {
                box-shadow: none;
            }
        }
    
        .swiper-slide {
            opacity: 0;
                visibility: hidden;
    
                &.swiper-slide-active,
                &.swiper-slide-next {
                    opacity: 1;
                    visibility: visible;
                }
        }
    }
    .shape-group {
        li {
            &.shape-1 {
                left: 0;
                bottom: 0;
            }
            &.shape-2 {
                bottom: inherit;
                right: -81px;
                top: -33px;
                z-index: 1;
            }
            &.shape-3 {
                top: 23px;
                right: -115px;
                z-index: 2;
            }
            img {
                opacity: 1;
            }
        }
    }
}
.testimonial-area-18 {
    overflow: hidden !important;
    max-height: 950px;
    position: relative;
    &::before,
    &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        pointer-events: none;
    }
    &::after {
        bottom: 0;
        min-height: 370px;
        background: linear-gradient(0deg,#ffffff 40%,#ffffff 0%,rgba(244,244,250,0) 100%);
        z-index: 10;
    }
    &::before {
        top: 0;
        min-height: 350px;
        background: linear-gradient(180deg, #ffffff 50%, #ffffff 0%, rgba(244, 244, 250, 0) 60%);
        z-index: 10;
    }
    .section-title {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 12;
    }
    .testimonial-wrapper {
        display: flex;
        margin-right: auto;
        margin-left: auto;
        position: relative;
        @media #{$md-layout-1} {
            display: block;
        }
        .testimonial-wrap {
            animation: eduBlinkTestimonialScroll 40s linear infinite;
            transition: all .5s ease-in;
            animation-duration: 35s;
            .testimonial-style-18 {
                display: flex;
                padding: 15px;
                .content {
                    padding: 45px 40px 40px 40px;
                    margin-top: 0;
                    margin-bottom: 0;
                }
            }
            &:hover {
                animation-play-state: paused;
            }
        }
    }
}
.testimonial-area-19 {
    background-color: var(--cta-color-bg);
    padding-top: 100px;
    overflow: hidden;
    .testimonial-bg-area {
        margin-top: -60px;
        @media #{$smlg-device} {
            margin-top: 0;
        } 
        @media #{$md-layout-1} {
            text-align: center;
        }
    }
    .gym-testimonial-activator {
            margin: 0 -15px;
            position: relative;
            .swiper-navigation {
                position: absolute;
                right: 113px;
                bottom: 113px;
                z-index: 1;
                @media #{$large-mobile} {
                    display: none;
                }
                .swiper-btn-nxt,
                .swiper-btn-prv {
                    border: 1px solid #E5E5E5;
                }
            }
    }
    .testimonial-style-2 {
        .content {
            width: 670px;
            height: 500px;
            box-shadow: none;
            padding: 60px 40px 70px 50px;
            border-radius: 10px;
            @media #{$smlg-device} {
                width: 100%;
                height: 100%;
                margin-top: 0;
                margin-bottom: 60px;
            }
            .pre-title {
                padding-bottom: 8px;
                display: block;
                text-transform: uppercase;
            }
            h3 {
                font-size: 36px;
                font-weight: var(--p-bold);
                margin-bottom: 0;
                @media #{$smlg-device} {
                    font-size: 28px;
                }
            }
            p {
                font-size: 22px;
                font-weight: var(--p-medium);
                margin-top: 30px;
                @media #{$smlg-device} {
                    font-size: 18px;
                }
            }
            .shape-line {
                display: block;
                color: var(--color-primary);
            }
            .author-info {
                margin-top: 35px;
            }
        }
    }
    .shape-group {
        li {
            &.shape-1 {
                left: -52px;
                top: 259px;
            }
            &.shape-2 {
                left: 0;
                top: 180px;
                z-index: 0;
            }
            &.shape-3 {
                right: -384px;
                top: -190px;
                span {
                    display: block;
                    height: 350px;
                    width: 350px;
                    border: 1px solid var(--color-border);
                    border-radius: 50%;
                }
            }
            &.shape-4 {
                right: -239px;
                top: 54px;
            }
        }
    }
}