.bookly-service-step .bookly-week-days > div, .bookly-repeat-step .bookly-week-days > div {
    text-align: center;
    min-width: 35px;
}

.bookly-form .bookly-form-group > label, .bookly-form .bookly-label-error, .bookly-form .bookly-progress-tracker > .active, .bookly-form .picker__nav--next, .bookly-form .pickadate__nav--prev, .bookly-form .picker__day:hover, .bookly-form .picker__day--selected:hover, .bookly-form .picker--opened .picker__day--selected, .bookly-form .picker__button--clear, .bookly-form .picker__button--today, .bookly-form .bookly-columnizer .bookly-hour span.bookly-waiting-list {
    color: #1e4e70 !important;
}

.bookly-form-group > label {
    display: inline-block;
    font-weight: bold;
    padding: 0 !important;
    margin: 0 !important;
}

.bookly-form label {
    display: inline;
    float: none;
    font-size: 14px;
}


.bk-session-date-area{
    // height: 40px;
}

.bk-session-date{
    border: 1px solid #e5e5e5 !important;
    border-radius: 5px;
    padding: 5px 3px 5px 5px !important;
    height: 40px;
    // margin-top: 20px;
    border-radius: 5px !important;
}

.bk-session-date:focus{
    border: 1px solid #e5e5e5 !important;
    color: #e5e5e5;
}

.bk-session-checkbox-area{
    margin-right: 25px;

}

.bk-session-checkbox-label{
    // margin-bottom: 0px !important;
    // font-size: 10px;
    font-size: 14px;
    // width: 20px;
    text-transform: capitalize;

}
.mo{
    background: #1e4e70 !important;
    color: #e5e5e5 !important;
    opacity: 1 !important;
    border-radius: 50% !important;
    height: 20px;
    /* right: 0; */
    /* left: 0; */
    width: 20px;
    margin-left: 2px;
    margin-top: 10px;
}

.ck-area{
    width: auto;
    display: flex;
    justify-content: center;
    margin-top: 5px;
}
.t{
    // width: auto !important;
    // width: 10px;
    display: flex !important; 
    justify-content: center !important;
    margin: 0 auto !important;
    height: 60px;
    // align-items: center;
    // margin-top: 5px;
}
.bs-availability-time{
    display: inline-block;
    font-weight: bold;
    padding: 0 !important;
    margin: 0 !important;
}

.date-time-area{
    margin-bottom: 20px;
    // color: #1e4e70 !important;
}

.bk-session-label{
    color: #1e4e70 !important;
    font-weight: bold;
}

.bk-session-date-t{
    border: 1px solid #e5e5e5 !important;
    height: 40px !important;
}


.bs-select{

    border-radius: 0px !important;
    background-color: var(--color-white);
    padding: 5px 0 5px 0 !important;
    border-bottom: 1px solid #e5e5e5 !important;
}

.bs-space-between-days{
    // margin-top: 20px;
    margin-bottom: 20px;
}

@media (max-width: 991px) {
    .bs-space-between-items {
      /* Styles spécifiques à une taille d'écran de 768 pixels ou moins */
      /* Par exemple : */
      margin-bottom: 20px;
    }
}

@media(max-width: 1199px){
    .bs-space-between-day-items{
        width: 300px;
    }
}

//Book session 2
.bs2-time-area{
    width: 150px;
    margin-right: 10px;
    border: 1px solid #1e4e70;
}

.bs2-radio-input{
    background: #1e4e70 !important;
    color: #e5e5e5 !important;
    opacity: 1 !important;
    border-radius: 50% !important;
    height: 20px;
    /* right: 0; */
    /* left: 0; */
    width: 20px;
    margin-left: 2px;
    margin-top: 10px;
}

.bs-form{
    margin-bottom: 60px !important;
}

.booksession-gap-top {
    margin-top: 30px;
}
